import React, { FC, memo } from 'react'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useSelector } from 'react-redux'
import { getColor } from 'astra-core/utils/sportColors'

import { ISportIconProps } from './SportIcon.types'
import { getSportSymbolId } from './constants'
import { StyledSportIcon } from './SportIcon.styled'

export const SportIcon: FC<ISportIconProps> = memo(
  ({ sportCode, size = 24, withBackground = false, ...props }) => {
    const typeTheme = useSelector(selectUserSettingLayoutTheme)
    const { main, background } = getColor(sportCode, typeTheme)
    const symbolId = getSportSymbolId(sportCode, typeTheme)

    return (
      <StyledSportIcon
        {...props}
        $backgroundColor={withBackground ? background : 'none'}
        $color={main}
        size={size}
      >
        <svg xmlns="http://www.w3.org/2000/svg">
          <use xlinkHref={`#${symbolId}`} />
        </svg>
      </StyledSportIcon>
    )
  }
)
