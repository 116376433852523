import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { selectBannersHost } from 'astra-core/containers/BannersProvider/selectors'

import {
  StyledBanner,
  StyledBannerSkeleton,
  StyledBannersLink
} from './Banners.styled'
import { BannerProps } from './Banners.types'

export const Banner: FC<BannerProps> = ({ banner }) => {
  const host = useSelector(selectBannersHost)
  const bannerUrl = useMemo(
    () => `${host}/${banner.imageId}`,
    [banner.imageId, host]
  )

  const [sourceLoaded, setSourceLoaded] = useState<string>('')

  const setImage = useCallback(() => setSourceLoaded(bannerUrl), [bannerUrl])

  useEffect(() => {
    const img = new Image()
    img.addEventListener('load', setImage, false)
    img.src = bannerUrl
    img.onerror = () => setSourceLoaded('')
    return () => {
      img.removeEventListener('load', setImage)
    }
  }, [bannerUrl, setImage])

  if (banner.link.includes('https')) {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <a href={banner.link} target="_blank" rel="noreferrer">
        <StyledBanner imageUrl={sourceLoaded} />
      </a>
    )
  }

  return (
    <StyledBannersLink to={banner.link} rel="noreferrer">
      <StyledBanner imageUrl={sourceLoaded} />
    </StyledBannersLink>
  )
}

export const BannerSkeleton: FC = () => <StyledBannerSkeleton imageUrl="" />
