import React from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from 'components/Onboarding/components'

export const OnboardingContentTopLive = () => {
  const { t } = useTranslation()

  return (
    <OnboardingContent title={t('topLive')}>
      {t('onboarding Top Live')}
    </OnboardingContent>
  )
}
