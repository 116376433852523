/* eslint-disable react-hooks/exhaustive-deps */
import {
  DependencyList,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react'

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

type ElementRef = MutableRefObject<HTMLElement | null>

const isBrowser = typeof window !== `undefined`

const getScrollPosition = ({
  element,
  useWindow
}: {
  element?: ElementRef
  boundingElement?: ElementRef
  useWindow?: boolean
}) => {
  if (useWindow) {
    return { x: window.scrollX, y: window.scrollY }
  }

  if (element?.current) {
    return {
      x: element.current.scrollLeft,
      y: element.current.scrollTop
    }
  }

  if (isBrowser) {
    return {
      x: document.body.scrollLeft,
      y: document.body.scrollTop
    }
  }

  return {
    x: 0,
    y: 0
  }
}

export const useScroll = (
  effect: (props) => void,
  element: ElementRef,
  deps?: DependencyList,
  wait?: number,
  useWindow?: boolean
): void => {
  let throttleTimeout: number | null = null

  const position = useRef(getScrollPosition({ element, useWindow }))

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    throttleTimeout = null
  }

  useEffect(() => {
    if (!isBrowser) {
      return undefined
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = window.setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    element.current?.addEventListener('scroll', handleScroll)

    return () => {
      if (element.current) {
        element.current?.removeEventListener('scroll', handleScroll)
      }

      if (throttleTimeout) {
        clearTimeout(throttleTimeout)
      }
    }
  }, deps)
}
