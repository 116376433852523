import React, { createContext, useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { iFrameRoutes, virtualGamesRoutes } from 'routes'

import { ERoutes } from './routes.types'

type TRouterContext = {
  pathname: string
  isCasino: boolean
  isFavourite: boolean
  isSearch: boolean
  isVirtualGame: boolean
  isTwain: boolean
  isIframeRoute: boolean
}
export const RouterContext = createContext<TRouterContext>({
  pathname: '',
  isCasino: false,
  isFavourite: false,
  isSearch: false,
  isVirtualGame: false,
  isTwain: false,
  isIframeRoute: false
})

export const RouterProvider = ({ children }) => {
  const { pathname } = useLocation()
  const isFavourite = pathname.includes(ERoutes.FavouriteEvents)
  const isSearch = pathname.includes(ERoutes.Search)
  const isCasino = useRouteMatch(ERoutes.Casino) !== null
  const isVirtualGame = useRouteMatch(Array.from(virtualGamesRoutes)) !== null
  const isTwain = useRouteMatch(ERoutes.TwainGames) !== null

  const isIframeRoute = useRouteMatch(iFrameRoutes) !== null

  const value: TRouterContext = useMemo(() => {
    return {
      pathname,
      isCasino,
      isFavourite,
      isSearch,
      isVirtualGame,
      isTwain,
      isIframeRoute
    }
  }, [
    pathname,
    isCasino,
    isFavourite,
    isSearch,
    isVirtualGame,
    isTwain,
    isIframeRoute
  ])

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  )
}
