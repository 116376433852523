import * as React from 'react'

import { FC } from 'react'

import { Icon } from 'components/ui/Icon/Icon'
import { IServiceIconProps } from 'components/ui/Icon/Icon.types'

export const IconVsport: FC<IServiceIconProps> = (props) => {
  return (
    <Icon size={24} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9555 2.64533C17.565 1.80065 15.9976 1.2206 14.3223 0.974609C15.646 2.92238 17.5306 7.01455 16.25 13.4513C16.25 6.406 12.8545 2.77423 10.2939 1.03711C8.60895 1.33874 7.04084 1.97974 5.66198 2.8873C13.1386 8.17218 15.892 19.1352 10.3019 25.1002C19.3643 20.6132 23.1671 9.85294 18.9555 2.64533ZM1.01517 8.43959C0.361901 9.96358 0 11.6435 0 13.4083C0 18.9788 3.60544 23.7027 8.59834 25.3504C15.3769 15.8952 5.76419 1.79004 1.01517 8.43959ZM10.6946 25.8445C19.7633 23.3947 24.224 14.6039 23.535 7.50002C24.47 9.26123 25 11.2725 25 13.4083C25 20.3485 19.4036 25.9746 12.5 25.9746C11.8869 25.9746 11.2841 25.9302 10.6946 25.8445Z"
          fill="url(#paint0_linear_2680_46775)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2680_46775"
            x1="12.5"
            y1="-2.48803"
            x2="24.5092"
            y2="22.9899"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2FBDFF" />
            <stop offset="1" stopColor="#4D6FFF" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  )
}
