import React from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'utils/testData'

import { IconApprove } from 'components/ui/Icon/General/IconApprove'
import {
  EColorsNames,
  EColorsTypes
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import { StyledPopUpNotificationSuccessLogin } from './PopUpNotificationSuccessLogin.styled'

export const PopUpNotificationSuccessLogin = (props) => {
  const [t] = useTranslation()

  return (
    <StyledPopUpNotificationSuccessLogin
      data-test-id={ETestData.TestPopUpNotificationSuccessLogin}
      {...props}
    >
      <IconApprove
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.DEFAULT,
          value: 0
        }}
        size={14}
      />
      {t('you have successfully logged in!')}
    </StyledPopUpNotificationSuccessLogin>
  )
}
