import { createContext, useContext } from 'react'

import { EOnboardingContentPositions } from 'components/Onboarding/Onboarding.types'
import { TOnboardingMaskProps } from 'components/Onboarding/components/OnboardingMask/OnboardingMask.types'

export type TOnboardingItemContext = Partial<{
  itemNodeRectProps: TOnboardingMaskProps
  positionContent: EOnboardingContentPositions
  onNextOnboardingItem: () => void
  onCloseOnboarding: () => void
  isHaveNext: boolean
}>

export const OnboardingItemContext = createContext<TOnboardingItemContext>({})

export const useOnboardingItemContext = () => {
  return useContext(OnboardingItemContext)
}
