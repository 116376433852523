export type FormatPriceParams = {
  value?: string | number
  currency?: string
  placeholder?: string
}

export const getDeclinationType = (number: number) => {
  const cases = [2, 0, 1, 1, 1, 2]

  return number % 100 > 4 && number % 100 < 20
    ? 2
    : cases[number % 10 < 5 ? Math.abs(number) % 10 : 5]
}

export const removeUnderscore = (text?: string) =>
  text && text.replaceAll('_', ' ').trim()
