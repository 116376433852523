import React, { FC } from 'react'

import { IServiceIconProps } from 'components/ui/Icon/Icon.types'

import { StyledIconLiveGames } from './IconLiveGames.styled'

export const IconLiveGames37: FC<IServiceIconProps> = (props) => {
  return (
    <StyledIconLiveGames size={40} {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Type=Livegames, Active=Yes, Dark theme=No">
          <g id="Vector">
            <path
              className="background"
              d="M6.18182 7.80556C6.18182 6.80838 6.99585 6 8 6H20.3636C21.3678 6 22.1818 6.80837 22.1818 7.80556V12.1389C22.1818 13.1361 21.3678 13.9444 20.3636 13.9444H8C6.99585 13.9444 6.18182 13.1361 6.18182 12.1389V7.80556Z"
              fill="#7F00FE"
            />
            <path
              className="background"
              d="M4 12.8611C4 11.8639 4.81403 11.0556 5.81818 11.0556H26.1818C27.186 11.0556 28 11.8639 28 12.8611V17.1944C28 18.1916 27.186 19 26.1818 19H5.81818C4.81403 19 4 18.1916 4 17.1944V12.8611Z"
              fill="#7F00FE"
            />
          </g>
          <g id="Vector_2">
            <path
              d="M8.20472 12.3784H10.5669V11.3227H9.38765V7H8.20472V12.3784Z"
              fill="white"
            />
            <path d="M12.0223 7H10.8393V12.3784H12.0223V7Z" fill="white" />
            <path
              d="M13.5927 7H12.2688L13.6051 12.3784H15.18L16.5163 7H15.8544H15.1924L14.4129 10.9393H14.3722L13.5927 7Z"
              fill="white"
            />
            <path
              d="M16.7599 12.3784H20.1724V11.3227H17.9428V10.2171H19.998V9.15872H17.9428V8.05573H20.1724V7H16.7599V12.3784Z"
              fill="white"
            />
            <path
              d="M8.90268 14.6409H9.96728C9.86103 13.6668 9.08755 12.9768 8.08032 12.9768C6.91798 12.9768 6 13.896 6 15.4956C6 17.0426 6.84573 18 8.09307 18C9.21292 18 10.014 17.2193 10.014 15.9062V15.2592H8.1377V16.1211H9.00043C8.9898 16.6368 8.67106 16.9639 8.10157 16.9639C7.44921 16.9639 7.0731 16.4219 7.0731 15.4812C7.0731 14.5478 7.47046 14.013 8.10157 14.013C8.52231 14.013 8.80918 14.2446 8.90268 14.6409Z"
              fill="white"
            />
            <path
              d="M10.8425 17.9332L11.1294 16.9066H12.6317L12.9186 17.9332H14.0491L12.5807 13.0437H11.1804L9.71207 17.9332H10.8425ZM11.3802 16.0089L11.8625 14.2756H11.8965L12.381 16.0089H11.3802Z"
              fill="white"
            />
            <path
              d="M13.9193 13.0437V17.9332H14.9435V14.913H14.9796L16.0251 17.9021H16.6796L17.725 14.9297H17.7612V17.9332H18.7854V13.0437H17.4828L16.3778 16.0709H16.3268L15.2218 13.0437H13.9193Z"
              fill="white"
            />
            <path
              d="M19.1635 17.9332H22.1979V16.9734H20.2154V15.9683H22.0428V15.0061H20.2154V14.0034H22.1979V13.0437H19.1635V17.9332Z"
              fill="white"
            />
            <path
              d="M24.9333 14.5096H25.9405C25.932 13.5952 25.2839 12.9768 24.2724 12.9768C23.2779 12.9768 22.5618 13.5856 22.5682 14.4952C22.5661 15.2377 23.0293 15.6555 23.7816 15.8489L24.2363 15.9683C24.7144 16.0924 24.929 16.2381 24.9333 16.5126C24.929 16.8111 24.6804 17.0188 24.2575 17.0188C23.7922 17.0188 23.4862 16.7752 23.4628 16.3049H22.4556C22.4683 17.4485 23.1759 17.9952 24.2703 17.9952C25.354 17.9952 25.9958 17.4437 26 16.515C25.9958 15.7343 25.5261 15.2568 24.6677 15.0443L24.2937 14.9488C23.8984 14.8534 23.6477 14.7053 23.6562 14.4141C23.6583 14.1467 23.8623 13.9533 24.2703 13.9533C24.6804 13.9533 24.9057 14.161 24.9333 14.5096Z"
              fill="white"
            />
          </g>
          <g id="37">
            <path
              className="numbers"
              d="M14.0239 26C16.295 26 17.8861 25.3334 17.8861 23.6667C17.8952 22.7124 17.1336 22.0305 15.6377 21.8936V21.8328C16.7438 21.7339 17.5507 21.137 17.5416 20.2663C17.5507 18.9545 16.1001 18.0002 14.042 18.0002C11.9387 18.0002 13.0241 18 11.7476 18L12.2187 19.7949C12.2187 19.7949 13.3485 19.7948 14.042 19.7948C14.6767 19.7948 15.1118 20.0044 15.1118 20.4032C15.1209 20.8746 14.5951 21.2092 13.8426 21.2092H12.4952L12.8997 22.6693H13.8426C14.6585 22.6693 15.2297 23.0039 15.2206 23.4753C15.2297 23.9582 14.7401 24.2966 14.042 24.2966C13.3167 24.2966 12.4952 24.3333 11.7476 24.3333L11 26C11.4136 26 11.8253 26 14.0239 26Z"
              fill="black"
            />
            <path
              className="numbers"
              d="M17.7286 25.8935H20.3215L25 18.1066H17.5239L18.2153 19.7948H21.3732V19.8404L17.7286 25.8935Z"
              fill="black"
            />
          </g>
        </g>
      </svg>
    </StyledIconLiveGames>
  )
}
