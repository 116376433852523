import styled, { css } from 'styled-components'

import { columnGapPrefixes } from 'utils/styled/stylePrefixes'

export const StyledPopUpNotificationSuccessLogin = styled.div`
  position: fixed;
  ${(props) => css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
    color: ${props.theme.colors.default.primary[0]};
    background-color: ${props.theme.colors.default.primary[90]};
    line-height: 20px;
    border-radius: 4px;
    opacity: 0.9;

    ${columnGapPrefixes('8px')}
  `}
`
