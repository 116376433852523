import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { EServiceIconPostion, IServiceIconProps } from '../../Icon.types'

export const StyledIconAviator = styled(StyledIcon)<IServiceIconProps>`
  ${({ position, theme }) => {
    if (position === EServiceIconPostion.HEADER) {
      return css`
        path {
          fill: ${theme.colors.fixed.primary[0]};
        }
      `
    }

    return css`
      path {
        fill: #e50539;
      }
    `
  }}
`
