import { keyBy, get } from 'utils/functions'

import { isBrowser } from 'utils/env'

import { RootStateKeyType } from './injector-typings'

export const toIdsArray = <T extends Record<string, any>>(
  items: T[],
  idKey = 'id'
) => items.map((item) => item[idKey])

export const toIdsMap = <T extends Record<string, any>>(
  items: T[],
  idKey = 'id'
) => keyBy(items, idKey)

export const getValues = <T>(
  idsArray: number[] | string[],
  itemsMap?: Dictionary<T>
): T[] => (itemsMap ? idsArray.map((id) => itemsMap[id]) : [])

export const getPreloadedState = <T>(
  key: RootStateKeyType,
  initialState: T
): T =>
  (isBrowser && (get(window.__PRELOADED_STATE__, key) as any)) || initialState
