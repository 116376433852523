import React, { CSSProperties } from 'react'

import { IconProps } from '../Icon/Icon.types'

export enum EButtonView {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ALWAYS_LIGHT = 'always_light',
  ALWAYS_DARK = 'always_dark',
  SELL_BET = 'sell_bet'
}

export enum EButtonSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l'
}

export enum EPositionSide {
  Left = 'left',
  Right = 'right'
}

export interface ButtonIconProps extends IconProps {
  positionSide?: EPositionSide // icon after content
  size?: number
  color?: string
  name?: string
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  view?: EButtonView // variants of theme
  size?: EButtonSize
  icon?: React.FC<IconProps> // components with icon
  round?: boolean // corners of button is round
  additionalText?: string
  iconProps?: ButtonIconProps // props of Icon component
  additionalTextStyles?: CSSProperties
}

export interface StyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export interface StyledButtonAdditionalTextProps {
  icon: React.FC<IconProps> | null
  iconPositionSide?: EPositionSide
  isTouchEffect: boolean
}
