import React, { FC } from 'react'

import { IServiceIconProps } from 'components/ui/Icon/Icon.types'

import { StyledIconCasino } from './IconCasino.styled'

export const IconCasino: FC<IServiceIconProps> = (props) => {
  return (
    <StyledIconCasino size={40} {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.83506 19.5C9.98117 19.5 10.6615 18.4926 10.6615 17.25C10.6615 16.0074 9.98117 15 7.83506 15C5.68894 15 4.96289 16.0074 4.96289 17.25C4.96289 18.4926 5.68894 19.5 7.83506 19.5ZM7.8242 18.4364C8.9513 18.4364 9.18561 17.9052 9.18561 17.25C9.18561 16.5948 8.9513 16.0636 7.8242 16.0636C6.69711 16.0636 6.43879 16.5948 6.43879 17.25C6.43879 17.9052 6.69711 18.4364 7.8242 18.4364Z"
          className="text"
        />
        <path
          d="M15.9501 15.2045C15.9501 15.1368 16.0052 15.0818 16.0731 15.0818H17.3031C17.371 15.0818 17.4261 15.1368 17.4261 15.2045V19.3364C17.4261 19.4041 17.371 19.4591 17.3031 19.4591H16.0731C16.0052 19.4591 15.9501 19.4041 15.9501 19.3364V15.2045Z"
          className="text"
        />
        <path
          d="M18.451 15.0818C18.3831 15.0818 18.328 15.1368 18.328 15.2045V19.3364C18.328 19.4041 18.3831 19.4591 18.451 19.4591H19.6809C19.7488 19.4591 19.8039 19.4041 19.8039 19.3364V16.9462L21.0001 19.3902C21.0207 19.4324 21.0636 19.4591 21.1106 19.4591H21.8195C21.8657 19.4591 21.908 19.4333 21.929 19.3922L23.2067 16.8988V19.3364C23.2067 19.4041 23.2617 19.4591 23.3297 19.4591H24.5596C24.6275 19.4591 24.6826 19.4041 24.6826 19.3364V15.2045C24.6826 15.1368 24.6275 15.0818 24.5596 15.0818H22.9541C22.9078 15.0818 22.8655 15.1078 22.8445 15.1489L21.4643 17.859L20.2059 15.1529C20.1858 15.1095 20.1422 15.0818 20.0943 15.0818H18.451Z"
          className="text"
        />
        <path
          d="M12.7934 15.2045C12.7934 15.1368 12.7383 15.0818 12.6704 15.0818H11.4405C11.3725 15.0818 11.3175 15.1368 11.3175 15.2045V19.3364C11.3175 19.4041 11.3725 19.4591 11.4405 19.4591H15.1067C15.1674 19.4591 15.219 19.4149 15.2282 19.355L15.3544 18.5368C15.3659 18.4625 15.3082 18.3955 15.2328 18.3955H12.7934V15.2045Z"
          className="text"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7895 15.0818C25.7216 15.0818 25.6665 15.1368 25.6665 15.2045V19.3364C25.6665 19.4041 25.7216 19.4591 25.7895 19.4591H27.0194C27.0873 19.4591 27.1424 19.4041 27.1424 19.3364V18.1091H28.7413C28.7497 18.1091 28.7582 18.1091 28.7667 18.1091C30.2105 18.1091 30.6682 17.4314 30.6682 16.5955C30.6682 15.7595 30.2105 15.0818 28.7667 15.0818C28.7582 15.0818 28.7497 15.0818 28.7413 15.0819L25.7895 15.0818ZM27.1424 16.1455V17.0455H28.7452C29.1153 17.0455 29.1923 16.8529 29.1923 16.5955C29.1923 16.3381 29.1153 16.1455 28.7452 16.1455H27.1424Z"
          className="text"
        />
        <path
          d="M5.71566 24.4479C5.21381 24.0798 4.96289 23.5277 4.96289 22.7916V22.6936C4.96289 21.9515 5.22472 21.4004 5.74839 21.0402C6.27205 20.6801 6.95936 20.5 7.81032 20.5C8.66921 20.5 9.3218 20.6415 9.76811 20.9245C10.2164 21.2055 10.4653 21.6537 10.5149 22.2691C10.5229 22.3552 10.4552 22.4294 10.3688 22.4294H9.03201C8.95624 22.4294 8.89236 22.3729 8.88313 22.2977L8.88145 22.284C8.85566 22.0327 8.75648 21.8555 8.58391 21.7526C8.41134 21.6497 8.16042 21.5983 7.83114 21.5983C7.47608 21.5983 7.19838 21.6834 6.99804 21.8536C6.7977 22.0237 6.69753 22.281 6.69753 22.6253V22.848C6.69753 23.2239 6.79671 23.498 6.99507 23.6702C7.19342 23.8404 7.47013 23.9255 7.82519 23.9255C8.18422 23.9255 8.44605 23.8701 8.61069 23.7592C8.77533 23.6464 8.86855 23.4753 8.89037 23.2457L8.89218 23.227C8.89959 23.1501 8.96422 23.0914 9.04149 23.0914H10.3722C10.4591 23.0914 10.5278 23.1649 10.5219 23.2515L10.5209 23.2665C10.4812 23.8562 10.2402 24.2925 9.79786 24.5755C9.35552 24.8585 8.69598 25 7.81924 25C6.92068 25 6.21949 24.816 5.71566 24.4479Z"
          fill="#FFA217"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6851 24.9109C10.5746 24.9109 10.502 24.7955 10.5499 24.6959L12.4943 20.6533C12.5193 20.6013 12.5719 20.5683 12.6295 20.5683H14.0712C14.1289 20.5683 14.1816 20.6014 14.2065 20.6536L16.1398 24.6962C16.1874 24.7958 16.1149 24.9109 16.0045 24.9109H14.4945C14.4341 24.9109 14.3796 24.8747 14.3562 24.819L14.1246 24.2668H12.4308L12.1961 24.8196C12.1726 24.875 12.1182 24.9109 12.0581 24.9109H10.6851ZM12.8719 23.2279H13.6887L13.401 22.5422C13.3733 22.473 13.3504 22.4126 13.3326 22.3611C13.3156 22.3103 13.3004 22.2666 13.287 22.23C13.2858 22.2268 13.2827 22.2246 13.2792 22.2246C13.2756 22.2246 13.2723 22.227 13.2713 22.2304C13.2598 22.2669 13.2465 22.3104 13.2314 22.3611C13.2156 22.4126 13.1928 22.473 13.163 22.5422L12.8719 23.2279Z"
          fill="#FFA217"
        />
        <path
          d="M16.2425 23.7236C16.2584 24.157 16.4885 24.4786 16.9328 24.6883C17.3771 24.8961 17.9821 25 18.7478 25C19.5114 25 20.1016 24.8724 20.5181 24.6171C20.9366 24.3598 21.1459 23.9858 21.1459 23.4951C21.1459 23.032 20.9793 22.7154 20.646 22.5452C20.3148 22.373 19.8248 22.2414 19.1762 22.1504C18.5117 22.0594 18.1279 21.9941 18.0247 21.9545C17.9216 21.9149 17.87 21.8615 17.87 21.7942C17.87 21.719 17.9266 21.6626 18.0396 21.625C18.1527 21.5874 18.3451 21.5686 18.6168 21.5686C18.9541 21.5686 19.1812 21.6003 19.2982 21.6636C19.4172 21.7249 19.4837 21.8051 19.4976 21.904C19.5044 21.9451 19.54 21.9753 19.5817 21.9753H20.8266C20.9116 21.9753 20.9796 21.9047 20.9765 21.8197L20.9763 21.815C20.9545 21.3757 20.7393 21.0472 20.3307 20.8295C19.924 20.6098 19.3438 20.5 18.5901 20.5C17.8502 20.5 17.2829 20.6227 16.8882 20.8681C16.4954 21.1115 16.299 21.4617 16.299 21.9189C16.299 22.3344 16.4607 22.6481 16.784 22.8598C17.1073 23.0696 17.6102 23.22 18.2925 23.311C18.9233 23.3941 19.2962 23.4604 19.4113 23.5099C19.5263 23.5594 19.5838 23.6247 19.5838 23.7058C19.5838 23.7968 19.5174 23.8641 19.3845 23.9077C19.2536 23.9492 19.0314 23.97 18.718 23.97C18.3828 23.97 18.1418 23.9433 17.995 23.8898C17.8502 23.8344 17.7679 23.7355 17.748 23.593C17.7377 23.5346 17.687 23.4921 17.6277 23.4921H16.3895C16.3044 23.4921 16.2363 23.5628 16.2396 23.6479L16.2425 23.7236Z"
          fill="#FFA217"
        />
        <path
          d="M21.8077 24.9109C21.7248 24.9109 21.6577 24.8438 21.6577 24.761V20.739C21.6577 20.6562 21.7248 20.5891 21.8077 20.5891H23.159C23.2418 20.5891 23.309 20.6562 23.309 20.7391V24.761C23.309 24.8438 23.2418 24.9109 23.159 24.9109H21.8077Z"
          fill="#FFA217"
        />
        <path
          d="M24.1332 24.761C24.1332 24.8438 24.2003 24.9109 24.2832 24.9109H25.3366C25.4196 24.9109 25.4868 24.8435 25.4866 24.7605L25.481 22.7292C25.481 22.7035 25.479 22.6728 25.4751 22.6372C25.4716 22.6063 25.4689 22.5754 25.467 22.5446C25.4665 22.5368 25.4726 22.5303 25.4804 22.5303C25.4861 22.5303 25.4912 22.534 25.4931 22.5393C25.5068 22.5778 25.5256 22.6272 25.5494 22.6877C25.5772 22.751 25.6119 22.8133 25.6536 22.8747L27.1439 24.8513C27.1722 24.8888 27.2166 24.9109 27.2636 24.9109H28.595C28.6778 24.9109 28.745 24.8438 28.745 24.761V20.7391C28.745 20.6562 28.6778 20.5891 28.595 20.5891H27.5412C27.4584 20.5891 27.3912 20.6562 27.3912 20.739V22.6491C27.3912 22.7025 27.3922 22.75 27.3942 22.7916C27.3978 22.8275 27.4014 22.8692 27.405 22.9165C27.4056 22.9244 27.3994 22.9311 27.3915 22.9311C27.3859 22.9311 27.381 22.9277 27.3789 22.9225C27.3652 22.8885 27.3465 22.8479 27.3228 22.8005C27.297 22.747 27.2682 22.6966 27.2365 22.6491L25.7551 20.6498C25.7268 20.6116 25.6821 20.5891 25.6346 20.5891H24.2832C24.2003 20.5891 24.1332 20.6562 24.1332 20.739V24.761Z"
          fill="#FFA217"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.1107 24.4627C29.5632 24.1046 29.2895 23.5406 29.2895 22.7708V22.6906C29.2895 21.9406 29.5632 21.3885 30.1107 21.0343C30.6601 20.6781 31.3425 20.5 32.1577 20.5C32.973 20.5 33.6563 20.6781 34.2078 21.0343C34.7612 21.3885 35.0379 21.9406 35.0379 22.6906V22.7708C35.0379 23.5406 34.7612 24.1046 34.2078 24.4627C33.6563 24.8209 32.973 25 32.1577 25C31.3425 25 30.6601 24.8209 30.1107 24.4627ZM30.9884 22.6342V22.8331C30.9884 23.2269 31.0916 23.5049 31.2979 23.6672C31.5061 23.8295 31.7938 23.9146 32.1607 23.9225C32.5257 23.9284 32.8133 23.8473 33.0236 23.6791C33.2338 23.5089 33.339 23.2289 33.339 22.8391V22.6372C33.339 22.2632 33.2338 22 33.0236 21.8476C32.8133 21.6953 32.5257 21.6151 32.1607 21.6072C31.7918 21.6013 31.5041 21.6784 31.2979 21.8387C31.0916 21.997 30.9884 22.2622 30.9884 22.6342Z"
          fill="#FFA217"
        />
      </svg>
    </StyledIconCasino>
  )
}
