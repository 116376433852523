import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  EColorsNames,
  EColorsTypes
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import {
  StyledIconClose,
  StyledIconTutorial,
  StyledNotification,
  StyledNotificationLink,
  StyledNotificationModalWindowText,
  StyledNotificationText,
  StyledNotificationWrapper
} from './Notification.styled'
import { NotificationProps } from './Notification.types'

export const Notification: FC<NotificationProps> = ({
  text,
  linkText,
  linkUrl = '/',
  isIconCloseShown,
  colors,
  testData,
  modalWindowAction
}) => {
  const { t } = useTranslation()

  return (
    <StyledNotificationWrapper>
      <StyledNotification backgroundColor={colors?.backgroundColor}>
        <StyledIconTutorial
          size={{
            height: 18,
            width: 16
          }}
          colorProps={{
            name: EColorsNames.Blue,
            value: 3,
            type: EColorsTypes.FIXED
          }}
        />
        <StyledNotificationText textColor={colors?.textColor}>
          {t(text)}{' '}
          {!!linkText &&
            (!modalWindowAction ? (
              <StyledNotificationLink to={linkUrl}>
                {t(linkText)}
              </StyledNotificationLink>
            ) : (
              <StyledNotificationModalWindowText
                data-test-id={testData}
                textColor={colors?.linkColor}
                onClick={modalWindowAction}
              >
                {t(linkText)}
              </StyledNotificationModalWindowText>
            ))}
        </StyledNotificationText>

        {isIconCloseShown && (
          <StyledIconClose
            size={{
              height: 18,
              width: 9
            }}
            colorProps={{
              name: EColorsNames.Primary,
              value: 40
            }}
            onClick={() => {}}
          />
        )}
      </StyledNotification>
    </StyledNotificationWrapper>
  )
}
