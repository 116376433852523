import styled, { css } from 'styled-components'

import { DEVICE } from 'utils/styled/media'

export const StyledSponsorWrapper = styled.div`
  padding: 0 16px 6px 16px;
`

export const StyledSponsorTitle = styled.div`
  line-height: 20px;
  padding-bottom: 14px;

  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledSponsorsItemsTemplatesWrapper = styled.div<{
  hidden: boolean
}>`
  ${({ hidden }) =>
    hidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
  flex-direction: column;
  gap: 8px;

  @media ${DEVICE.mobileS} {
    gap: 6px;
  }
`

export const SponsorsGridStyled = styled.div<{ isVsportEnabled }>`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 8px;

  @media ${DEVICE.mobileS} {
    gap: 6px;
  }

  ${({ isVsportEnabled }) =>
    isVsportEnabled
      ? ''
      : css`
          & > :first-child {
            grid-column: span 2;
          }
        `}
`

export const SponsorsFlexStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${DEVICE.mobileS} {
    gap: 6px;
  }
`

export const StyledSponsorImg = styled.img<{ isTouched: boolean }>`
  height: 100%;
  width: 100%;

  ${({ isTouched }) =>
    isTouched
      ? css`
          transform: scale(0.97);
        `
      : null}
`

export const StyledSponsorImgSkeleton = styled.img`
  height: 10px;
  width: 10px;
  background: red;
`
