import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { IServiceIconProps } from '../../Icon.types'

export const StyledIconLiveGames = styled(StyledIcon)<IServiceIconProps>`
  ${({ isActive, theme }) => {
    if (!isActive) {
      return css`
        path {
          fill: ${theme.colors.default.primary[0]};
        }
        .background {
          fill: ${theme.colors.custom.primary[16]};
        }
        .numbers {
          fill: ${theme.colors.custom.primary[14]};
        }
      `
    }

    return css`
      .numbers {
        fill: ${theme.colors.custom.primary[0]};
      }
    `
  }}
`
