import styled from 'styled-components'

export const CarouselItem = styled.div`
  scroll-snap-align: start;
`

export const CarouselWrapper = styled.div`
  overflow: auto;
  position: relative;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0.5rem;

  scroll-snap-type: x mandatory;
  padding: 0 1rem;
  scroll-padding: 0 1rem;

  -ms-overflow-style: none; /* IE and Edge */
`
