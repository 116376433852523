import { useEffect, useRef, useState } from 'react'

import { useOnboardingItemContext } from 'components/Onboarding/Onboarding.context'

import { getPositionData } from './utils'
import { ICoordTooltip } from './OnboardingContent.types'

export const useOnboardingContentCoords = () => {
  const onboardingContentRef = useRef<HTMLDivElement>(null)
  const [coords, setCoords] = useState<ICoordTooltip>()
  const { positionContent, itemNodeRectProps } = useOnboardingItemContext()

  useEffect(() => {
    if (itemNodeRectProps && onboardingContentRef.current && positionContent) {
      const blockCoords = getPositionData({
        position: positionContent,
        itemNodeRectProps,
        onboardingContentBlockProps: onboardingContentRef.current
      })

      setCoords(blockCoords)
    }
  }, [positionContent, itemNodeRectProps])

  return { coords, onboardingContentRef }
}
