import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
  selectBannerItems,
  selectBannersLoading
} from 'astra-core/containers/BannersProvider/selectors'

import { bannersProviderActions } from 'astra-core/containers/BannersProvider/slice'

import { CarouselApi } from 'components/ui/Carousel/Carousel.types'
import { useInterval } from 'hooks'

import { StyledBannerWrapper, StyledBannersCarousel } from './Banners.styled'
import { Banner, BannerSkeleton } from './Banner'

const BANNERS_SCROLL_TIMEOUT = 5 * 1000

export const Banners: FC = () => {
  const carouselApi = useRef<CarouselApi>()
  const banners = useSelector(selectBannerItems)
  const loading = useSelector(selectBannersLoading)
  const [currentIndex, setCurrentIndex] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(bannersProviderActions.fetchBanners())
  }, [dispatch])

  const handleIndexChange = useCallback(
    (index: number) => setCurrentIndex(index),
    []
  )

  const scrollToNext = useCallback(() => {
    if (carouselApi.current) {
      if (currentIndex + 1 < banners.length) {
        setCurrentIndex((prev) => {
          carouselApi.current?.scrollTo(currentIndex + 1)
          return prev + 1
        })
      } else {
        setCurrentIndex((prev) => {
          carouselApi.current?.scrollTo(0)
          return prev
        })
      }
    }
  }, [banners.length, currentIndex])

  useInterval(scrollToNext, BANNERS_SCROLL_TIMEOUT)

  return (
    <StyledBannerWrapper>
      <StyledBannersCarousel
        onCarouselMounted={(api) => {
          carouselApi.current = api
        }}
        onIndexChange={handleIndexChange}
      >
        {loading !== false
          ? Array.from(new Array(5)).map((_item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BannerSkeleton key={index} />
            ))
          : banners.map((banner) => <Banner key={banner.id} banner={banner} />)}
      </StyledBannersCarousel>
    </StyledBannerWrapper>
  )
}
