import { css } from 'styled-components'

export const columnGapPrefixes = (size: string) => css`
  -webkit-column-gap: ${size};
  -moz-column-gap: ${size};
  column-gap: ${size};
`

export const rowGapPrefixes = (size: string) => css`
  -webkit-row-gap: ${size};
  -moz-row-gap: ${size};
  row-gap: ${size};
`
