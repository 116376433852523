import styled, { keyframes } from 'styled-components'

import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledEventCardWrapper } from '../../EventCard.styled'

const SkeletonShine = keyframes`
  0%, 25% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const StyledEventCardWrapperSkeleton = styled(StyledEventCardWrapper)`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.custom.primary[7]};
  height: 141px;
  border: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
