import * as React from 'react'

import { FC } from 'react'

import { Icon } from 'components/ui/Icon/Icon'
import { IServiceIconProps } from 'components/ui/Icon/Icon.types'

export const IconCyberbet: FC<IServiceIconProps> = (props) => {
  return (
    <Icon size={24} {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.0522 12.0041L19.0692 12.0041L19.0482 11.9826L23.8341 7.09035C24.0553 6.8643 24.0553 6.4971 23.8341 6.27003L17.7023 0.00307196L17.6993 0.00307196L17.6993 1.13369e-06L11.1553 1.41974e-06C11.0052 1.4263e-06 10.8601 0.0613723 10.754 0.169794L8.31849 2.65939C8.13938 2.84249 8.26646 3.15445 8.51862 3.15445L16.3635 3.15445C16.5136 3.15445 16.6587 3.21582 16.7647 3.32424L19.7706 6.39686C19.9918 6.62291 19.9918 6.99011 19.7706 7.21719L16.6287 10.4289C16.5226 10.5373 16.3775 10.5987 16.2274 10.5987L9.46121 10.5987L9.46521 10.6018L7.6871 10.6018C7.53701 10.6018 7.39192 10.6631 7.28585 10.7716L6.27722 11.7944C6.16616 11.9069 6.16515 12.091 6.27722 12.2046L7.27885 13.2284C7.38491 13.3369 7.53 13.3982 7.6801 13.3982L9.4582 13.3982L9.4542 13.4013L16.2204 13.4013C16.3705 13.4013 16.5156 13.4627 16.6217 13.5711L19.7636 16.7828C19.9848 17.0089 19.9848 17.3761 19.7636 17.6031L16.7577 20.6758C16.6517 20.7842 16.5066 20.8456 16.3565 20.8456L8.51161 20.8456C8.25846 20.8456 8.13238 21.1575 8.31149 21.3406L10.747 23.8302C10.8531 23.9386 10.9982 24 11.1483 24L17.6923 24L17.6923 23.9969L17.6953 23.9969L23.8271 17.7289C24.0483 17.5029 24.0483 17.1357 23.8271 16.9086L19.0412 12.0164L19.0522 12.0041Z"
          fill="#00E25B"
        />
        <path
          d="M15.9798 15.9889C15.8776 15.8825 15.7377 15.8223 15.5929 15.8223L7.35891 15.8193C7.21418 15.8193 7.07428 15.7591 6.97201 15.6527L4.04658 12.6053C3.72529 12.27 3.72528 11.728 4.04658 11.3937L6.97201 8.34632C7.07428 8.23992 7.21419 8.17969 7.35891 8.17969L15.5929 8.17668C15.7377 8.17668 15.8776 8.11646 15.9798 8.01006L16.9505 7.00931C17.0595 6.89689 17.0595 6.7132 16.9505 6.60078L15.9876 5.59903C15.8853 5.49263 15.7454 5.43241 15.6007 5.43241L6.19241 5.41936C6.04671 5.41936 5.90778 5.47958 5.80454 5.58698L0.240247 11.3947C-0.0800831 11.729 -0.0800831 12.271 0.240247 12.6053L5.80454 18.413C5.90681 18.5204 6.04671 18.5806 6.19241 18.5806L15.6007 18.5676C15.7454 18.5676 15.8853 18.5074 15.9876 18.401L16.9447 17.4052C17.0566 17.2888 17.0557 17.1011 16.9447 16.9857L15.9798 15.9889Z"
          fill="#00E25B"
        />
      </svg>
    </Icon>
  )
}
