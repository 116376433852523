import { FC } from 'react'

import { ERoutes } from 'routes/routes.types'
import { IServiceIconProps } from 'components/ui/Icon/Icon.types'

export interface SponsorsItemProps {
  url: ERoutes | string
  icon: FC<IServiceIconProps>
  name: string
  testData?: string // ETestData.TestFixedHeaderMenu-<SPONSOR_NAME>
}

export enum ESrcSet {
  '320w' = '320w',
  '375w' = '375w'
}

export type SponsorsImagesSrcSet = Record<ESrcSet, 'string'>

export interface SponsorsItemImgProps {
  url: ERoutes | string
  srcSet: SponsorsImagesSrcSet
  name: string
  onImageLoad?: () => void
}

export enum ESponsors {
  AVIATOR = 'Aviator',
  AVIATOR_SMALL = 'Aviator small',
  BET_GAMES = 'Bet Games',
  TWAIN_SPORT = 'Twain sport',
  LIVE_GAMES_37 = 'Live Games 37',
  SPORT_GAMES = 'Sport Games',
  FAST_GAMES = 'Fast Games',
  AVIATRIX = 'Aviatrix',
  CYBERBET = 'Cyberbet',
  VSPORT = 'Vsport',
  FRIGG = 'Frigg'
}

type Sponsors = Exclude<ESponsors, ESponsors.FRIGG>
export type ImagesSet = Record<Sponsors, SponsorsImagesSrcSet>
