import styled, { keyframes } from 'styled-components'

import { Link } from 'react-router-dom'

import { LayoutTheme } from 'betweb-openapi-axios'

import { Carousel } from 'components/ui/Carousel/Carousel'

import { StyledBannerControlProps, StyledBannerProps } from './Banners.types'

export const StyledBannerWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 16px 0;
`

export const StyledBannersCarousel = styled(Carousel)`
  padding: 0 1rem;
`
export const StyledBannersLink = styled(Link)``

export const StyledBanner = styled.div<StyledBannerProps>`
  width: 320px;
  height: 125px;

  background: ${(p) =>
      p.imageUrl ? `url(${p.imageUrl})` : p.theme.colors.custom.primary[7]}
    no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
`

export const StyledBannerControlsWrapper = styled.div`
  padding: 0 1rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.25rem;
  grid-auto-columns: 24px;
  grid-template-rows: 2px;
`

export const StyledBannerControl = styled.div<StyledBannerControlProps>`
  background-color: ${(p) =>
    p.isActive
      ? p.theme.colors.default.primary[90]
      : p.theme.colors.default.primary[10]};
`
const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`
export const StyledBannerSkeleton = styled(StyledBanner)`
  width: 320px;
  height: 125px;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 70%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
