import React, { FC } from 'react'

import { IconProps } from '../Icon.types'
import { Icon } from '../Icon'

export const IconApprove: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6975 0.283399C14.0933 0.668608 14.1018 1.30172 13.7166 1.69748L5.93004 9.69748C5.74103 9.89168 5.48129 10.0008 5.21029 10C4.9393 9.99914 4.68025 9.88834 4.49247 9.69296L0.279028 5.30921C-0.103685 4.91103 -0.0911441 4.27798 0.307039 3.89527C0.705222 3.51256 1.33826 3.5251 1.72097 3.92328L5.21796 7.56163L12.2834 0.302518C12.6686 -0.0932503 13.3017 -0.10181 13.6975 0.283399Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
