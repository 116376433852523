import { useRef, useState, useCallback, useEffect } from 'react'

import { CarouselApi } from 'components/ui/Carousel/Carousel.types'

export const useCarouselScroll = (events) => {
  const carouselApi = useRef<CarouselApi>()
  const [shouldScroll, setShouldScroll] = useState(false)

  const scrollToIndex = useCallback(() => {
    setShouldScroll(true)
  }, [setShouldScroll])

  useEffect(() => {
    if (shouldScroll) {
      carouselApi.current?.scrollTo(0)
      setShouldScroll(false)
    }
  }, [shouldScroll, events])

  return {
    carouselApi,
    scrollToIndex
  }
}

// For optimized events carousel there are non need to track events size
export const useCarouselScrollOptimized = () => {
  const carouselApi = useRef<CarouselApi>()

  const scrollToIndex = useCallback(() => {
    carouselApi.current?.scrollTo(0)
  }, [])

  return {
    carouselApi,
    scrollToIndex
  }
}
