import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useOnboardingItemContext } from 'components/Onboarding/Onboarding.context'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { EOnboardingType } from 'components/Onboarding/Onboarding.types'

import {
  EColorsTypes,
  EColorsNames
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import { useOnboardingContentCoords } from './hooks'
import {
  StyledIconTooltipTriangle,
  StyledOnboardingContent,
  StyledOnboardingContentButtonNext,
  StyledOnboardingContentButtonSkip,
  StyledOnboardingContentButtonWrapper,
  StyledOnboardingContentHeader,
  StyledOnboardingContentText
} from './OnboardingContent.styled'
import { IOnboardingContentProps } from './OnboardingContent.types'

export const OnboardingContent: FC<IOnboardingContentProps> = ({
  children,
  title,
  ...props
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { coords, onboardingContentRef } = useOnboardingContentCoords()
  const {
    onNextOnboardingItem,
    onCloseOnboarding,
    isHaveNext,
    positionContent
  } = useOnboardingItemContext()

  const onRepeatOnboarding = useCallback(() => {
    dispatch(layoutContainerActions.resetOnboarding())

    setTimeout(() => {
      dispatch(
        layoutContainerActions.setOnboarding({
          isDisplay: true,
          type: EOnboardingType.MAIN_PAGE_WELCOME
        })
      )
    }, 300)
  }, [dispatch])

  return (
    <StyledOnboardingContent
      {...props}
      positionCoords={coords}
      ref={onboardingContentRef}
    >
      <div>
        <StyledIconTooltipTriangle
          position={positionContent}
          leftTr={coords?.leftTr}
          topTr={coords?.topTr}
          colorProps={{
            type: EColorsTypes.FIXED,
            name: EColorsNames.Primary,
            value: 0
          }}
        />
        <StyledOnboardingContentHeader>{title}</StyledOnboardingContentHeader>

        <StyledOnboardingContentText>{children}</StyledOnboardingContentText>
      </div>
      <StyledOnboardingContentButtonWrapper>
        {isHaveNext ? (
          <>
            <StyledOnboardingContentButtonSkip onClick={onCloseOnboarding}>
              {t('skip')}
            </StyledOnboardingContentButtonSkip>
            <StyledOnboardingContentButtonNext onClick={onNextOnboardingItem}>
              {t('next')}
            </StyledOnboardingContentButtonNext>
          </>
        ) : (
          <>
            <StyledOnboardingContentButtonSkip onClick={onRepeatOnboarding}>
              {t('repeat')}
            </StyledOnboardingContentButtonSkip>
            <StyledOnboardingContentButtonNext onClick={onCloseOnboarding}>
              {t('got it')}
            </StyledOnboardingContentButtonNext>
          </>
        )}
      </StyledOnboardingContentButtonWrapper>
    </StyledOnboardingContent>
  )
}
