import styled from 'styled-components'

import {
  STYLE_BASKET_BET_BOTTOM_MENU_HEIGHT_PX,
  STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX,
  STYLE_MAIN_HEADER_HEIGHT_PX,
  STYLE_DOWNLOAD_APP_BANNER_HEIGHT
} from 'constants/styles'
import { PopUpNotificationSuccessLogin } from 'components/PopUpNotification/PopUpNotificationSuccessLogin'
import { SUCCESS_LOGIN_ANIMATION_DURATION_SEC } from 'components/Layout/components/Default/constants'

import { StyledContentProps } from './Default.types'

export const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-flow: column nowrap;
  min-height: ${({ isBasketPanel, isDownloadAppBannerVisible }) =>
    !isBasketPanel
      ? `calc(
        100dvh - ${STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX} -
          ${STYLE_MAIN_HEADER_HEIGHT_PX} ${
          isDownloadAppBannerVisible
            ? `- ${STYLE_DOWNLOAD_APP_BANNER_HEIGHT}`
            : ''
        }
      )`
      : `calc(
        100dvh - ${STYLE_BASKET_BET_BOTTOM_MENU_HEIGHT_PX} -
          ${STYLE_MAIN_HEADER_HEIGHT_PX} ${
          isDownloadAppBannerVisible
            ? `- ${STYLE_DOWNLOAD_APP_BANNER_HEIGHT}`
            : ''
        }
      )`};
  margin-bottom: ${({ isBasketPanel }) =>
    !isBasketPanel
      ? `${STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX}`
      : `${STYLE_BASKET_BET_BOTTOM_MENU_HEIGHT_PX}`};
  background: ${(props) => props.theme.colors.default.primary[0]};
`

export const StyledPopUpNotificationSuccessLogin = styled(
  PopUpNotificationSuccessLogin
)`
  position: fixed;
  left: 16px;
  bottom: 0;
  width: calc(100% - 32px);
  animation-name: slideUpKeyframes;
  animation-duration: ${SUCCESS_LOGIN_ANIMATION_DURATION_SEC}s;
  z-index: 1;

  @keyframes slideUpKeyframes {
    0% {
      bottom: 0;
    }
    20%,
    80% {
      bottom: calc(${STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX} + 9px);
    }
    100% {
      bottom: 0;
    }
  }
`
