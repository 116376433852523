import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { EServiceIconPostion, IServiceIconProps } from '../../Icon.types'

export const StyledTwainSport = styled(StyledIcon)<IServiceIconProps>`
  ${({ position, isActive, theme }) => {
    if (position === EServiceIconPostion.HEADER) {
      return css`
        path {
          fill: ${theme.colors.fixed.primary[0]};
        }
      `
    }

    if (!isActive) {
      return css`
        path {
          fill: ${theme.colors.custom.primary[16]};
        }
      `
    }

    return css`
      path {
        fill: ${theme.colors.custom.primary[0]};
      }
    `
  }}
`
