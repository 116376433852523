import range from 'lodash/range'
import React from 'react'

import { EventCardSkeleton } from 'components/Main/EventCard/components/EventCardSkeleton/EventCardSkeleton'

import { EventsCarouselSkeletonWrapper } from './EventsCarouselSkeleton.styled'

interface IEventsCarouselSkeleton {
  count?: number
}
const EventsCarouselSkeleton: React.FC<IEventsCarouselSkeleton> = ({
  count = 10
}) => {
  return (
    <EventsCarouselSkeletonWrapper>
      {range(count).map((item) => (
        <EventCardSkeleton key={item} />
      ))}
    </EventsCarouselSkeletonWrapper>
  )
}

export default React.memo(EventsCarouselSkeleton)
