import React, { FC, memo, useCallback, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ESportsCodes,
  getSportById
} from 'astra-core/containers/CommonDataProvider'

import { getColor } from 'astra-core/utils/sportColors'

import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'

import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { range } from 'utils/functions'

import { removeUnderscore } from 'utils/format'

import { MainPageEventsContext } from '../MainPageEvents.context'
import { getMainPageEventsConfiguration } from '../MainPageEvents.constants'

import { EMainPageEventsList } from '../MainPageEvents.types'

import { SportsListProps, SportsItemProps } from './SportsList.types'
import {
  StyledSportsListItem,
  StyledSportsListItemIcon,
  StyledSportsListFirstItemSkeleton,
  StyledSportsListItemSkeleton,
  StyledSportsListItemName,
  StyledSportsListItemNameWrapper,
  StyledSportsSkeletonWrapper,
  StyledSportsWrapper
} from './SportsList.styled'

export const SportsList: FC<SportsListProps> = memo(() => {
  const { mainPageEventsListType } = useContext(MainPageEventsContext)

  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)

  const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

  const { selectSports, selectCurrentSport } = config[mainPageEventsListType]

  const currentSport = useSelector(selectCurrentSport)

  const sports = useSelector(selectSports)

  return (
    <StyledSportsWrapper>
      {sports.map((sport) => (
        <SportItem
          key={sport.id}
          sportId={sport.id}
          isActive={sport.id === currentSport}
        />
      ))}
    </StyledSportsWrapper>
  )
})

export const SportsListSkeleton: FC<{
  mainPageEventsListType: EMainPageEventsList
}> = memo(({ mainPageEventsListType }) => {
  return (
    <StyledSportsSkeletonWrapper
      mainPageEventsListType={mainPageEventsListType}
    >
      <StyledSportsListFirstItemSkeleton />
      {range(15).map((item) => (
        <StyledSportsListItemSkeleton key={item} />
      ))}
    </StyledSportsSkeletonWrapper>
  )
})

const SportItem: FC<SportsItemProps> = memo(({ sportId, isActive }) => {
  const { mainPageEventsListType, scrollToIndex } = useContext(
    MainPageEventsContext
  )

  const dispatch = useDispatch()
  const menuItemRef = useRef<HTMLDivElement>(null)
  const sport = useSelector((state) => getSportById(state, sportId))
  const layoutTheme = useSelector(selectUserSettingLayoutTheme)

  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)

  const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

  const { setCurrentSport } = config[mainPageEventsListType]

  const handleSportItemClick = useCallback(() => {
    dispatch(setCurrentSport({ sportId: sport!.id }))
    scrollToIndex()
  }, [dispatch, setCurrentSport, scrollToIndex, sport])

  if (sport === undefined) {
    return null
  }

  return (
    <StyledSportsListItem
      isActive={isActive}
      onClick={handleSportItemClick}
      bgColor={getColor(sport.code as ESportsCodes, layoutTheme).background}
      ref={menuItemRef}
      isRefBound={!!menuItemRef.current}
    >
      <StyledSportsListItemIcon
        isActive={isActive}
        sportCode={sport.code as ESportsCodes}
        size={24}
      />
      {isActive && (
        <StyledSportsListItemNameWrapper>
          <StyledSportsListItemName
            isActive={isActive}
            textColor={getColor(sport.code as ESportsCodes, layoutTheme)?.main}
          >
            {removeUnderscore(sport.name)}
          </StyledSportsListItemName>
        </StyledSportsListItemNameWrapper>
      )}
    </StyledSportsListItem>
  )
})
