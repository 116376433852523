import { useContext } from 'react'

import { RouterContext } from 'routes/context'

interface IUseCurrentPath {
  path: string
  isFragment?: boolean
}

export function useCurrentPath({ path, isFragment }: IUseCurrentPath): boolean {
  const { pathname } = useContext(RouterContext)

  return !!(
    (pathname === path && !isFragment) ||
    (pathname.includes(path) && isFragment)
  )
}
