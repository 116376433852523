import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import loadable from '@loadable/component'

import { isEqual } from 'lodash'

import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'

import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { useOnboardingAttr } from 'components/Onboarding/hooks'

import { EOnboardingType } from 'components/Onboarding/Onboarding.types'

import { useCarouselScrollOptimized } from 'hooks/useCarouselScroll'

import { CarouselApi } from 'components/ui/Carousel/Carousel.types'

import { SportsList, SportsListSkeleton } from './SportsList'
import { MainPageEventsContext } from './MainPageEvents.context'
import {
  EMainPageEventsList,
  MainPageEventsProps
} from './MainPageEvents.types'
import {
  EVENTS_TO_ONBOARDING_ATTRS_MAP,
  getMainPageEventsConfiguration
} from './MainPageEvents.constants'
import { StyledSportsListWrapper } from './SportsList/SportsList.styled'
import {
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper
} from './MainPageEvents.styled'
import EventsCarouselSkeleton from './EventsCarousel/EventsCarouselSkeleton'

const VirtualizedEventsCarousel = loadable(
  () => import('./EventsCarousel/VirtualizedEventsCarousel'),
  {
    resolveComponent: (m) => m.VirtualizedEventsCarousel,
    fallback: <EventsCarouselSkeleton />
  }
)

const LiveLineEvents: FC<MainPageEventsProps> = memo(
  ({ mainPageEventsListType }) => {
    const isEntityTagsEnabled = useFeatureFlag(
      EFeatureFlags.ENTITY_TAGS_ENABLED
    )

    const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

    const {
      untranslatedTitle,
      selectLoading,
      selectEvents,
      selectIsEventsIdsBySport
    } = config[mainPageEventsListType]

    const { t } = useTranslation()

    const events = useSelector(selectEvents) // TODO Make request by condition considering last fetch date
    const loading = useSelector(selectLoading)
    const isEventsIdsBySport = useSelector(selectIsEventsIdsBySport)
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {
      if (loading === false) {
        setInitialLoad(false)
      }
    }, [loading])

    const showSkeleton = initialLoad || loading

    const isEmpty =
      initialLoad === false &&
      loading === false &&
      events.length === 0 &&
      isEventsIdsBySport

    const eventsIds = useMemo(() => {
      return events.map((e) => e.id)
    }, [events])

    if (isEmpty) {
      return null
    }

    return (
      <LiveLineCarousel
        eventsIds={eventsIds}
        title={t(untranslatedTitle)}
        showSkeleton={showSkeleton}
        type={mainPageEventsListType}
      />
    )
  }
)

interface ILiveLineCarousel {
  eventsIds: number[]
  title: string
  showSkeleton: boolean
  type: EMainPageEventsList
}

/*
It depends only on eventsIds, title, showSkeleton, type which we can easily to check on isEqual
and uses VirtualizedEventsCarousel
That way it doesn't re-render each time some event changed (by SSE i.e.).
*/

const LiveLineCarousel: FC<ILiveLineCarousel> = React.memo((props) => {
  const { eventsIds, title, showSkeleton, type } = props
  const { carouselApi, scrollToIndex } = useCarouselScrollOptimized()
  const { onboardingAttr } = useOnboardingAttr(
    EVENTS_TO_ONBOARDING_ATTRS_MAP[type],
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  const mainPageCtx = useMemo(
    () => ({ mainPageEventsListType: type, scrollToIndex }),
    [type, scrollToIndex]
  )

  const onCarouselMounted = useCallback(
    (api: CarouselApi) => {
      carouselApi.current = api
    },
    [carouselApi]
  )

  return (
    <MainPageEventsContext.Provider value={mainPageCtx}>
      <StyledMainPageEventsWrapper>
        <StyledMainPageEventsHeaderWrapper>
          <StyledMainPageEventsHeader {...onboardingAttr}>
            {title}
          </StyledMainPageEventsHeader>
        </StyledMainPageEventsHeaderWrapper>

        {showSkeleton ? (
          <>
            <SportsListSkeleton mainPageEventsListType={type} />
            <EventsCarouselSkeleton />
          </>
        ) : (
          <>
            <StyledSportsListWrapper>
              <SportsList />
            </StyledSportsListWrapper>
            <VirtualizedEventsCarousel
              eventsIds={eventsIds}
              onCarouselMounted={onCarouselMounted}
            />
          </>
        )}
      </StyledMainPageEventsWrapper>
    </MainPageEventsContext.Provider>
  )
}, isEqual)

export default LiveLineEvents
