import styled from 'styled-components'

export const StyledMainPageEventsWrapper = styled.div`
  display: grid;
  overflow-y: auto;
`

export const StyledMainPageEventsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 1rem;
  column-gap: 0.5rem;
`

export const StyledMainPageEventsHeader = styled.span`
  font-size: ${(p) => p.theme.fonts.size.l};
  color: ${(p) => p.theme.colors.custom.primary[0]};
  font-weight: ${(p) => p.theme.fonts.weight.bold};
  flex-grow: 1;

  padding: 10px;
  margin: -10px;
`
