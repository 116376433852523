import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from 'components/Onboarding/components'

export const OnboardingContentNearestEvents: FC = (props) => {
  const [t] = useTranslation()

  return (
    <OnboardingContent title={t('upcoming')} {...props}>
      {t('onboarding upcoming events')}
    </OnboardingContent>
  )
}
