import React, { FC } from 'react'

import { PictureProps } from './Picture.types'
import { StyledPicture } from './Picture.styled'

export const Picture: FC<PictureProps> = ({ sources, children }) => {
  return (
    <StyledPicture>
      {sources.map(([media, src]) => (
        <source media={media} srcSet={src} key={src} />
      ))}
      {children}
    </StyledPicture>
  )
}
