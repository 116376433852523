import styled, { css } from 'styled-components'

import { StyledIcon } from 'components/ui/Icon/Icon.styled'

import {
  ButtonProps,
  StyledButtonAdditionalTextProps,
  EButtonView,
  EButtonSize,
  EPositionSide
} from './Button.types'

export const StyledButton = styled.button<ButtonProps>`
  ${(props) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    border-radius: 4px;
    font-weight: ${props.theme.fonts.weight.normal};
    font-size: ${props.theme.fonts.size.s};
    line-height: 16px;
    white-space: nowrap;
    outline: none;
    transition: background-color 0.2s;

    ${StyledIcon} + span {
      margin-left: 8px;
    }

    ${props.size === EButtonSize.XS &&
    css`
      padding: 4px 8px;
      height: 24px;
    `}

    ${props.size === EButtonSize.S &&
    css`
      padding: 6px 8px;
      height: 24px;
    `}

    ${props.size === EButtonSize.M &&
    css`
      padding: 10px 8px;
      height: 32px;
    `}

    ${props.size === EButtonSize.L &&
    css`
      padding: 10px;
      height: 40px;
    `}

    ${props.view === EButtonView.SECONDARY &&
    css`
      background-color: ${props.theme.colors.custom.primary[4]};
      color: ${props.theme.colors.custom.primary[13]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]};
      }

      &:active {
        background-color: ${props.theme.colors.default.primary[15]};
        color: ${props.theme.colors.default.primary[90]};
      }

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[20]};
        color: ${props.theme.colors.custom.primary[21]};
      }
    `}
    
    ${props.view === EButtonView.PRIMARY &&
    css`
      background-color: ${props.theme.colors.default.primary[90]};
      color: ${props.theme.colors.custom.primary[2]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]};
      }

      &:active {
        background-color: ${props.theme.colors.custom.primary[19]};
      }

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[20]};
        color: ${props.theme.colors.custom.primary[21]};
      }
    `}

    ${props.view === EButtonView.SELL_BET &&
    css`
      background-color: ${props.theme.colors.default.primary[90]};
      color: ${props.theme.colors.custom.primary[2]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]};
      }

      &:disabled {
        background-color: ${props.theme.colors.custom.primary[4]};
        color: ${props.theme.colors.custom.primary[14]};
      }
    `}

    ${props.view === EButtonView.ALWAYS_LIGHT &&
    css`
      background-color: ${props.theme.colors.fixed.primary[0]};
      color: ${props.theme.colors.fixed.primary[90]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]};
      }

      &:active {
        background-color: ${props.theme.colors.default.primary[70]};
      }

      &:disabled {
        background-color: ${props.theme.colors.default.primary[30]};
        color: ${props.theme.colors.default.primary[10]};
      }
    `}

    ${props.view === EButtonView.ALWAYS_DARK &&
    css`
      background-color: ${props.theme.colors.fixed.primary[80]};
      color: ${props.theme.colors.fixed.primary[30]};

      ${StyledIcon} {
        color: ${props.theme.colors.default.primary[20]};
      }

      &:active {
        background-color: ${props.theme.colors.default.primary[70]};
      }

      &:disabled {
        background-color: ${props.theme.colors.default.primary[30]};
        color: ${props.theme.colors.default.primary[10]};
      }
    `}

    ${props.round &&
    css`
      border-radius: 32px;
    `}
  `}
`

export const StyledButtonAdditionalText = styled.span<StyledButtonAdditionalTextProps>`
  ${({ theme, iconPositionSide, isTouchEffect }) => css`
    color: ${theme.colors.default.primary[90]};
    margin-left: ${iconPositionSide === EPositionSide.Left ? '10px' : '0'};
    text-overflow: ellipsis;
    overflow: hidden;
}
    ${
      isTouchEffect &&
      css`
        color: ${theme.colors.default.primary[0]};
      `
    }
  `}
`

export const StyledIconWrap = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
`

export const StyledButtonChildren = styled.span`
  width: 100%;
`
