import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'astra-core/store/RootState'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { Notification } from 'components/Notification'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { EOnboardingType } from 'components/Onboarding/Onboarding.types'
import {
  EColorsNames,
  EColorsTypes
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import { StyledOnboardingNotification } from './OnboardingNotification.styled'

export const OnboardingNotification = () => {
  const dispatch = useDispatch()
  const isOnboardPassed = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'onboardPassed')
  )

  const onDisplayWelcomeOnboarding = useCallback(() => {
    dispatch(
      layoutContainerActions.setOnboarding({
        isDisplay: true,
        type: EOnboardingType.MAIN_PAGE_WELCOME
      })
    )
  }, [dispatch])

  return isOnboardPassed ? null : (
    <StyledOnboardingNotification>
      <Notification
        text="mainPage tutorial text"
        linkText="mainPage tutorial link"
        modalWindowAction={onDisplayWelcomeOnboarding}
        colors={{
          linkColor: {
            name: EColorsNames.Primary,
            value: 70,
            type: EColorsTypes.DEFAULT
          },
          backgroundColor: {
            name: EColorsNames.Blue,
            value: 9
          }
        }}
        // isIconCloseShown
      />
    </StyledOnboardingNotification>
  )
}
