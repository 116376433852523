import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { getPreloadedState } from 'utils/@reduxjs'

import { EOnboardingType } from 'components/Onboarding/Onboarding.types'

import {
  ContainerState,
  SetDisplayWelcomeScreenPayload,
  SetOnboardingPayload,
  ToggleShowBasketUnauthorizedUserPayload
} from './types'

const REDUCER_KEY = 'layoutContainer'

// The initial state of the LayoutContainer container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  isHiddenWelcomeScreen: false,
  isShowBasketUnauthorizedUser: false,
  onboarding: {
    isDisplay: false,
    type: EOnboardingType.MAIN_PAGE_WELCOME
  }
})

const layoutContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setHiddenWelcomeScreen(
      state,
      { payload }: PayloadAction<SetDisplayWelcomeScreenPayload>
    ) {
      state.isHiddenWelcomeScreen = payload
    },
    setOnboarding(state, { payload }: PayloadAction<SetOnboardingPayload>) {
      state.onboarding = {
        ...state.onboarding,
        ...payload
      }
    },
    resetOnboarding(state) {
      state.onboarding = initialState.onboarding
    },
    setShowBasketUnauthorizedUser(
      state,
      { payload }: PayloadAction<ToggleShowBasketUnauthorizedUserPayload>
    ) {
      state.isShowBasketUnauthorizedUser = payload.isShow
    }
  }
})

export const {
  actions: layoutContainerActions,
  reducer,
  name: sliceKey
} = layoutContainerSlice
