import React from 'react'

import { EMainPageEventsList } from './MainPageEvents.types'
import UpcomingEvents from './UpcomingEvents'
import LiveLineEvents from './LiveLineEvents'

const MainPageEventsList = () => {
  return (
    <>
      <LiveLineEvents mainPageEventsListType={EMainPageEventsList.TOP_LIVE} />
      <LiveLineEvents mainPageEventsListType={EMainPageEventsList.TOP_LINE} />
      <UpcomingEvents mainPageEventsListType={EMainPageEventsList.UPCOMING} />
    </>
  )
}

export default MainPageEventsList
