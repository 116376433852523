import React, { ComponentProps, FC, useState } from 'react'

import {
  StyledButton,
  StyledButtonAdditionalText,
  StyledButtonChildren,
  StyledIconWrap
} from './Button.styled'
import {
  ButtonProps,
  EButtonSize,
  EButtonView,
  EPositionSide
} from './Button.types'

const Button: FC<
  Omit<ComponentProps<typeof StyledButton>, keyof ButtonProps> & ButtonProps
> = ({
  children,
  view = EButtonView.PRIMARY,
  size = EButtonSize.M,
  icon: Icon = null,
  iconProps = { positionSide: EPositionSide.Left },
  additionalText,
  additionalTextStyles,
  ...props
}) => {
  const [isTouchEffect, setIsTouchEffect] = useState(false)
  const iconRight = iconProps?.positionSide === EPositionSide.Right

  const changeTouchEffect = (flag: boolean) => () => setIsTouchEffect(flag)

  return (
    <StyledButton
      view={view}
      size={size}
      {...props}
      onTouchStart={changeTouchEffect(true)}
      onTouchEnd={changeTouchEffect(false)}
      onTouchCancel={changeTouchEffect(false)}
    >
      {!!Icon && !iconRight && <Icon {...iconProps} />}
      {!!children && <StyledButtonChildren>{children}</StyledButtonChildren>}
      {!!additionalText && (
        <StyledButtonAdditionalText
          isTouchEffect={isTouchEffect}
          icon={Icon}
          iconPositionSide={iconProps.positionSide}
          style={additionalTextStyles}
        >
          {additionalText}
        </StyledButtonAdditionalText>
      )}
      {!!Icon && iconRight && (
        <StyledIconWrap>
          <Icon {...iconProps} />
        </StyledIconWrap>
      )}
    </StyledButton>
  )
}

export default Button
