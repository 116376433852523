import React, { FC } from 'react'

import { range } from 'utils/functions'

import {
  StyledEventsTableHeadSkeleton,
  StyledEventsTableHeadPlug,
  StyledEventsTableWrapperSkeleton,
  StyledTableEventBetsSkeleton,
  StyledTableEventsDateSkeleton,
  StyledTableEventBetsRowSkeleton,
  StyledEventInfoCellSkeleton,
  StyledEventProbabilityCell,
  StyledEventProbabilityCells,
  StyledEventCell,
  StyledEventsTableHeadLeftPlug,
  StyledEventsTableHeadLeftPlugWrapper,
  StyledTableEventsDatePlug,
  StyledEventInfoCellPlugWrapper,
  StyledEventInfoCellPlugOne,
  StyledEventInfoCellPlugThree,
  StyledEventInfoCellPlugTwo,
  StyledEventInfoCellPlugTime,
  StyledEventInfoCellPlugTop
} from './EventsTableSkeleton.styled'

export const EventsTableSkeleton: FC = () => {
  return (
    <StyledEventsTableWrapperSkeleton>
      <StyledEventsTableHeadSkeleton>
        <StyledEventsTableHeadPlug />
        <StyledEventsTableHeadLeftPlugWrapper>
          <StyledEventsTableHeadLeftPlug />
          <StyledEventsTableHeadLeftPlug />
          <StyledEventsTableHeadLeftPlug />
        </StyledEventsTableHeadLeftPlugWrapper>
      </StyledEventsTableHeadSkeleton>
      <StyledTableEventBetsSkeleton>
        <StyledTableEventsDateSkeleton>
          <StyledTableEventsDatePlug />
        </StyledTableEventsDateSkeleton>
        {range(3).map((item) => (
          <StyledTableEventBetsRowSkeleton key={item}>
            <StyledEventInfoCellSkeleton>
              <StyledEventInfoCellPlugWrapper>
                <StyledEventInfoCellPlugOne />
                <StyledEventInfoCellPlugTwo />
                <StyledEventInfoCellPlugThree>
                  <StyledEventInfoCellPlugTop />
                  <StyledEventInfoCellPlugTime />
                </StyledEventInfoCellPlugThree>
              </StyledEventInfoCellPlugWrapper>
            </StyledEventInfoCellSkeleton>
            <StyledEventProbabilityCells>
              {range(3).map((item) => (
                <StyledEventProbabilityCell key={item}>
                  <StyledEventCell />
                </StyledEventProbabilityCell>
              ))}
            </StyledEventProbabilityCells>
          </StyledTableEventBetsRowSkeleton>
        ))}
      </StyledTableEventBetsSkeleton>
    </StyledEventsTableWrapperSkeleton>
  )
}
