import { useEffect, useRef, useState } from 'react'

export const useIsWindowScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const contentHeight = contentRef.current?.offsetHeight || 0
      const viewportHeight = window.innerHeight

      if (
        currentScrollPos > 100 &&
        !isScrolled &&
        contentHeight > viewportHeight
      ) {
        setIsScrolled(true)
      } else if (currentScrollPos <= 10 && isScrolled) {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isScrolled])

  return { isScrolled, contentRef }
}
