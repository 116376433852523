import styled, { css, keyframes } from 'styled-components'

import { LayoutTheme } from 'betweb-openapi-axios'

import { SportIcon } from 'components/ui/Icon/SportIcon/SportIcon'

import { columnGapPrefixes } from 'utils/styled/stylePrefixes'

import { EMainPageEventsList } from '../MainPageEvents.types'

import {
  StyledSportsListItemNameProps,
  StyledSportsListItemProps
} from './SportsList.types'

export const StyledSportsListWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  padding: 0.25rem 1rem;
`

export const StyledSportsListItem = styled.div<StyledSportsListItemProps>`
  display: flex;
  height: 40px;
  padding: 8px;
  column-gap: 0.25rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, isActive, bgColor, isRefBound }) => css`
    color: ${isActive
      ? theme.colors.default.primary[90]
      : theme.colors.default.primary[50]};
    background-color: ${isActive ? bgColor : theme.colors.default.primary[0]};

    ${isActive
      ? css`
          animation-name: transitionMenuLineItem;
          animation-duration: 0.2s;
          animation-iteration-count: ${isRefBound ? '1' : '0'};

          @keyframes transitionMenuLineItem {
            0% {
              opacity: 0.7;
              transform: translateX(0);
              width: 20%;
            }
            100% {
              opacity: 1;
              width: 100%;
            }
          }

          @-o-keyframes transitionMenuLineItem {
            0% {
              opacity: 0.7;
              transform: translateX(0);
              width: 20%;
            }
            100% {
              opacity: 1;
              width: 100%;
            }
          }

          @-ms-keyframes transitionMenuLineItem {
            0% {
              opacity: 0.7;
              transform: translateX(0);
              width: 20%;
            }
            100% {
              opacity: 1;
              width: 100%;
            }
          }

          @-moz-keyframes transitionMenuLineItem {
            0% {
              opacity: 0.7;
              transform: translateX(0);
              width: 20%;
            }
            100% {
              opacity: 1;
              width: 100%;
            }
          }
        `
      : ''}
  `}
`

const SkeletonShine = keyframes`
  0%, 25% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const StyledSportsListFirstItemSkeleton = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  height: 40px;
  width: 90px;
  background-color: ${(p) => p.theme.colors.custom.primary[7]};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 70%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
export const StyledSportsListItemSkeleton = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0.25rem 0.5rem;
  column-gap: 0.25rem;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  height: 40px;
  width: 40px;
  background-color: ${(p) => p.theme.colors.custom.primary[7]};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 70%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const StyledSportsListItemLabel = styled.span`
  font-size: ${(p) => p.theme.fonts.size.m};
  white-space: nowrap;
`

export const StyledSportsListItemIcon = styled(
  SportIcon
)<StyledSportsListItemProps>`
  background-color: transparent;

  path {
    fill: ${(p) => !p.isActive && p.theme.colors.default.primary[50]};
  }
`

export const StyledSportsListItemNameWrapper = styled.div`
  overflow: hidden;
`

export const StyledSportsListItemName = styled.div<StyledSportsListItemNameProps>`
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${({ textColor, isActive, theme }) => css`
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.semiBold};
    color: ${isActive ? textColor : theme.colors.fixed.primary[80]};
  `};
`

export const StyledSportsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  overflow-x: auto;
  ${columnGapPrefixes('8px')}

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const StyledSportsSkeletonWrapper = styled(StyledSportsWrapper)<{
  mainPageEventsListType
}>`
  padding-left: 16px;
  ${({ mainPageEventsListType }) => `
    ${
      mainPageEventsListType === EMainPageEventsList.UPCOMING &&
      `margin-bottom: 8px;`
    }
    `};
`
