import { RootState } from 'store/RootState'

import { initialState } from './slice'

export const selectIsHiddenWelcomeScreen = (state: RootState) =>
  state.layoutContainer.isHiddenWelcomeScreen ??
  initialState.isHiddenWelcomeScreen

export const getOnboardingIsDisplay = (state: RootState) =>
  state.layoutContainer.onboarding.isDisplay ??
  initialState.onboarding.isDisplay

export const getOnboardingType = (state: RootState) =>
  state.layoutContainer.onboarding.type || initialState.onboarding.type

export const selectIsShowBasketUnauthorizedUser = (state: RootState) =>
  state.layoutContainer.isShowBasketUnauthorizedUser ??
  initialState.isShowBasketUnauthorizedUser
