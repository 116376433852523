import React, { FC } from 'react'

import { range } from 'utils/functions'

import {
  GridContainer,
  RectangleHorizontal,
  Square
} from './SponsorsItemsSkeleton.styled'

export const SponsorsItemsSkeleton: FC = () => (
  <GridContainer>
    <RectangleHorizontal />
    {range(5).map((item) => (
      <Square key={item} />
    ))}
    <RectangleHorizontal />
  </GridContainer>
)
