import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { IServiceIconProps } from '../../Icon.types'

export const StyledIconBetGames = styled(StyledIcon)<IServiceIconProps>`
  ${({ isActive, theme }) => {
    if (!isActive) {
      return css`
        path {
          fill: ${theme.colors.custom.primary[16]};
        }
      `
    }

    return css`
      .text {
        fill: ${theme.colors.custom.blue[18]};
      }
    `
  }}
`
