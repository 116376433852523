import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { isBrowser } from 'utils/env'
import { getOnboardingIsDisplay } from 'containers/LayoutContainer/selectors'

import { StyledContent } from 'components/Layout/components/Default/Default.styled'

import { TOnboardingMaskProps } from './components/OnboardingMask/OnboardingMask.types'
import { getFormattedOnboardingAttr } from './utils'
import { IGetFormattedOnboardingAttr } from './Onboarding.types'
import { SCROLL_OFFSET } from './constants'

function getScrollParent(node: HTMLElement | null) {
  // TODO: remove this func and transfer node as prop without auto find like now because sometimes layouts css might be incorrect created and node.scrollHeight cant have correct value
  if (node === null) {
    return null
  }

  const spareHeight = 5 // Scroll height might be more than real client height when occur not correct create layout css

  if (node.scrollHeight > node.clientHeight + spareHeight) {
    //
    return node
  } else {
    return getScrollParent(node.parentElement)
  }
}

function getElementCoordinates(element: Element, container: Element) {
  const elementRect = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  const x = elementRect.left - containerRect.left
  const y = elementRect.top - containerRect.top

  return { x, y, width: elementRect.width, height: elementRect.width }
}

export const useGetOnboardingNodeItemClientRect = () => {
  return useCallback(
    (
      attrData?: IGetFormattedOnboardingAttr,
      toScroll?: boolean
    ): TOnboardingMaskProps | undefined => {
      if (isBrowser && attrData) {
        const itemNode = document.querySelector<HTMLDivElement>(
          `*[${getFormattedOnboardingAttr(attrData)}]`
        )

        const mainWrapper = document.querySelector(StyledContent) // select main wrapper scroller

        if (itemNode && mainWrapper) {
          if (toScroll) {
            const scrollParent = getScrollParent(itemNode)

            if (scrollParent) {
              const children = mainWrapper.firstChild as Element

              if (children) {
                const { y: previousTopYCoord } = getElementCoordinates(
                  itemNode,
                  children
                )

                if (previousTopYCoord > SCROLL_OFFSET) {
                  scrollParent.scrollTo({
                    top: previousTopYCoord - SCROLL_OFFSET
                  })
                }
              }
            }
          }

          const { left, top, width, height } = itemNode.getBoundingClientRect()

          return {
            leftTopX: left,
            leftTopY: top,
            width,
            height
          }
        }
      }

      return undefined
    },
    []
  )
}

export const useOnboardingAttr = (
  attrType: IGetFormattedOnboardingAttr['attrType'],
  onboardingType: IGetFormattedOnboardingAttr['onboardingType'],
  isPrivate?: boolean
) => {
  const result = getFormattedOnboardingAttr({ attrType, onboardingType })
  const attr = { [result]: true }
  const isDisplayOnboarding = useSelector(getOnboardingIsDisplay)
  const user = useSelector(selectAccountUser)

  if (isPrivate && !user) return {}

  return isDisplayOnboarding
    ? {
        onboardingAttr: attr,
        OnboardingAttrWrapper: ({ children, ...props }) => (
          <span {...attr} {...props}>
            {children}
          </span>
        )
      }
    : {
        OnboardingAttrWrapper: ({ children }) => children
      }
}
