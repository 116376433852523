import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from 'components/Onboarding/components'

export const OnboardingContentTopLine: FC = (props) => {
  const { t } = useTranslation()

  return (
    <OnboardingContent title={t('topLine')} {...props}>
      {t('onboarding Top Line')}
    </OnboardingContent>
  )
}
