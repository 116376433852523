import { Sport } from 'betweb-openapi-axios'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

import { EventEnriched } from 'astra-core/containers/EventsProvider/types'

import { SelectSportPayload } from 'og-core/providers/main-page/types'

import { RootState } from 'store/RootState'

export enum EMainPageEventsRepresentation {
  TABLE = 'TABLE',
  CAROUSEL = 'CAROUSEL'
}

export enum EMainPageEventsList {
  TOP_LIVE,
  TOP_LINE,
  UPCOMING
}

export interface MainPageEventsProps {
  mainPageEventsListType: EMainPageEventsList
}

export type MainPageEventsConfiguration<T extends RootState = RootState> =
  Record<
    EMainPageEventsList,
    {
      selectCurrentSport: (state: T) => number
      selectSports: (state: T) => Sport[]
      selectEvents: (state: T) => EventEnriched[]
      selectLoading: (state: T) => boolean
      setCurrentSport: ActionCreatorWithPayload<SelectSportPayload>
      untranslatedTitle: string
      representation: EMainPageEventsRepresentation
      selectIsEventsIdsBySport: (state: T) => boolean
    }
  >
