import React, { FC, memo, useMemo, useReducer } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { useSponsorsImageLoading } from 'astra-core/hooks/useSponsorsImageLoading'

import useAvailableSponsors from 'hooks/useSporsAvailable'

import { Picture } from '../../ui/Picture'

import { ESponsors, SponsorsItemImgProps } from './Sponsors.types'
import { SPONSORS_LIST_GRID, SPONSORS_LIST_FLEX } from './constants'
import {
  StyledSponsorImg,
  SponsorsGridStyled,
  StyledSponsorsItemsTemplatesWrapper,
  StyledSponsorTitle,
  StyledSponsorWrapper,
  SponsorsFlexStyled
} from './Sponsors.styled'
import { SponsorsItemsSkeleton } from './components/SponsorsItemsSkeleton/SponsorsItemsSkeleton'

export const Sponsors: FC = () => {
  const [t] = useTranslation()

  const { allImagesLoaded, handleImageLoad } = useSponsorsImageLoading(
    SPONSORS_LIST_GRID.length - 1 + SPONSORS_LIST_FLEX.length - 1
  )

  return (
    <StyledSponsorWrapper>
      <StyledSponsorTitle>{t('services')}</StyledSponsorTitle>
      <SponsorsItems onImageLoad={handleImageLoad} hidden={!allImagesLoaded} />
      {!allImagesLoaded && <SponsorsItemsSkeleton />}
    </StyledSponsorWrapper>
  )
}

const SponsorsItems = ({ onImageLoad, hidden }) => {
  const { isVsportEnabled, isFriggEnabled } = useAvailableSponsors()

  const renderedSponsorsListGrid = useMemo(
    () =>
      SPONSORS_LIST_GRID.filter((sponsor) => {
        if (sponsor.name === ESponsors.FRIGG) {
          return isFriggEnabled
        } else {
          return isVsportEnabled
            ? sponsor.name !== ESponsors.AVIATOR
            : !(
                sponsor.name === ESponsors.AVIATOR_SMALL ||
                sponsor.name === ESponsors.VSPORT
              )
        }
      }),
    [isFriggEnabled, isVsportEnabled]
  )

  return (
    <StyledSponsorsItemsTemplatesWrapper hidden={hidden}>
      <SponsorsGridStyled isVsportEnabled={isVsportEnabled}>
        {renderedSponsorsListGrid.map((sponsor) => (
          <SponsorsItem
            key={sponsor.name}
            onImageLoad={onImageLoad}
            {...sponsor}
          />
        ))}
      </SponsorsGridStyled>

      <SponsorsFlexStyled>
        {SPONSORS_LIST_FLEX.map((sponsor) => (
          <SponsorsItem
            key={sponsor.name}
            onImageLoad={onImageLoad}
            {...sponsor}
          />
        ))}
      </SponsorsFlexStyled>
    </StyledSponsorsItemsTemplatesWrapper>
  )
}

const SponsorsItem: FC<SponsorsItemImgProps> = memo(
  ({ name, url, srcSet, onImageLoad }) => {
    const [isTouched, setIsTouched] = useReducer(
      (_, type: boolean) => type,
      false
    )

    return (
      <Link to={url}>
        <Picture
          sources={[
            ['(max-width: 374px)', srcSet['320w']],
            ['(min-width: 375px)', srcSet['375w']]
          ]}
        >
          <StyledSponsorImg
            src={srcSet['375w']}
            alt={name}
            onTouchStart={() => setIsTouched(true)}
            onTouchEnd={() => setIsTouched(false)}
            isTouched={isTouched}
            onLoad={onImageLoad}
          />
        </Picture>
      </Link>
    )
  }
)
