export const BREAKPOINTS = {
  DESKTOP: {
    minWidth: 1025,
    maxWidth: 1280
  }
}

export const DEVICE = {
  mobile: `(max-width: 319px)`,
  mobileS: `(max-width: 374px)`,
  mobileXS: `(max-width: 380px)`
}
