import React, { FC } from 'react'

import { IconProps } from '../Icon.types'
import { Icon } from '../Icon'

export const IconTutorial: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V12C16 13.1046 15.1046 14 14 14H5L3.70711 15.2929C3.07714 15.9229 2 15.4767 2 14.5858V14C0.895431 14 0 13.1046 0 12V2ZM7 4C7 3.44769 7.44769 3 8 3C8.55231 3 9 3.44769 9 4V7C9 7.55231 8.55231 8 8 8C7.44769 8 7 7.55231 7 7V4ZM7 10C7 9.44769 7.44769 9 8 9C8.55231 9 9 9.44769 9 10C9 10.5523 8.55231 11 8 11C7.44769 11 7 10.5523 7 10Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
