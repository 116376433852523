import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { keyframes } from 'styled-components'

const SkeletonShine = keyframes`
  0%, 25% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const StyledEventsTableWrapperSkeleton = styled.div`
  display: grid;
`

export const StyledEventsTableHeadSkeleton = styled.div`
  height: 25px;
  background: ${(props) => props.theme.colors.default.primary[5]};
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 19px 0 16px;
`

export const StyledEventsTableHeadPlug = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 130px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const StyledEventsTableHeadLeftPlugWrapper = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: space-between;
`

export const StyledEventsTableHeadLeftPlug = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.custom.primary[7]};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const StyledTableEventBetsSkeleton = styled.div``

export const StyledTableEventsDateSkeleton = styled.div`
  height: 25px;
  background: ${(props) => props.theme.colors.default.primary[5]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
`

export const StyledTableEventsDatePlug = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 103px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const StyledTableEventBetsRowSkeleton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: 64px;
  border-bottom: 1px solid ${(props) => props.theme.colors.default.primary[5]};
  background-color: ${(props) => props.theme.colors.default.primary[0]};
`

export const StyledEventInfoCellSkeleton = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.default.primary[5]};
  padding-left: 16px;
  display: flex;
  align-items: center;
`

export const StyledEventInfoCellPlugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
export const StyledEventInfoCellPlugOne = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 91px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
export const StyledEventInfoCellPlugTwo = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 68px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
export const StyledEventInfoCellPlugThree = styled.div`
  height: 14px;
  display: flex;
  gap: 4px;
`
export const StyledEventInfoCellPlugTop = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 21px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
export const StyledEventInfoCellPlugTime = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  width: 81px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const StyledEventProbabilityCells = styled.div`
  display: flex;
  width: 147px;
`

export const StyledEventProbabilityCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border-right: 1px solid ${(props) => props.theme.colors.default.primary[5]};
`

export const StyledEventCell = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[7]};
  border-radius: 4px;
  height: 14px;
  width: 28px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 60%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
