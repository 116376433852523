import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { EServiceIconPostion, IServiceIconProps } from '../../Icon.types'

export const StyledIconFastGames = styled(StyledIcon)<IServiceIconProps>`
  ${({ position, isActive, theme }) => {
    if (position === EServiceIconPostion.HEADER) {
      return css`
        path {
          fill: ${theme.colors.fixed.primary[0]};
        }

        .gamesText {
          fill: ${theme.colors.fixed.primary[100]};
        }
      `
    }

    if (!isActive) {
      return css`
        path {
          fill: ${theme.colors.custom.primary[16]};
        }
        .gamesText {
          fill: ${theme.colors.default.primary[0]};
        }
      `
    }

    return css`
      .background {
        fill: ${theme.colors.custom.blue[33]};
      }

      .gamesText {
        fill: ${theme.colors.fixed.primary[0]};
      }
    `
  }}
`
