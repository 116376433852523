import React from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingContent } from 'components/Onboarding/components'

export const OnboardingContentInputFind = () => {
  const [t] = useTranslation()

  return (
    <OnboardingContent title={t('search')}>
      {t('onboarding search')}
    </OnboardingContent>
  )
}
