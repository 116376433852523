import {
  EFeatureFlags,
  selectConfig,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ERoutes } from 'routes/routes.types'

const useAvailableSponsors = () => {
  const config = useSelector(selectConfig)
  const isCasinoEnabled = !!config.CASINO_CLIENT_URL
  const isVsportEnabled = useFeatureFlag(EFeatureFlags.VSPORT_ENABLED)

  // TODO: OL-614 remove after release
  const isFriggEnabled = useFeatureFlag(EFeatureFlags.FRIGG_ENABLED)

  const underFeatureFlag = useMemo(
    () => ({
      byPath: {
        [ERoutes.Casino]: isCasinoEnabled,
        [ERoutes.VsportGames]: isVsportEnabled,
        [ERoutes.Frigg]: isFriggEnabled
      }
    }),
    [isCasinoEnabled, isVsportEnabled, isFriggEnabled]
  )

  const isEnabledByPath = useCallback(
    (r: ERoutes) => {
      return underFeatureFlag.byPath[r] ?? true
    },
    [underFeatureFlag]
  )

  const sponsorAvaialable = useMemo(
    () => ({
      isCasinoEnabled,
      isFriggEnabled,
      isVsportEnabled,
      sponsorPathEnabled: isEnabledByPath
    }),
    [isCasinoEnabled, isFriggEnabled, isVsportEnabled, isEnabledByPath]
  )

  return sponsorAvaialable
}

export default useAvailableSponsors
