import { EWelcomeOnboardingDataAttrs } from 'components/Onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import {
  EOnboardingContentPositions,
  EOnboardingType,
  TOnboardingList
} from '../../Onboarding.types'

import {
  OnboardingContentBalance,
  OnboardingContentFavouritesEvents,
  OnboardingContentInputFind,
  OnboardingContentNearestEvents,
  OnboardingContentTopLine,
  OnboardingContentTopLive,
  OnboardingContentMicroservices
} from './components'

export const ONBOARDING_WELCOME_LIST: TOnboardingList = [
  {
    attrType: EWelcomeOnboardingDataAttrs.INPUT_FIND,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentInputFind,
    positionContent: EOnboardingContentPositions.BOTTOM_CENTER
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.FAVOURITES_EVENTS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentFavouritesEvents,
    positionContent: EOnboardingContentPositions.BOTTOM_CENTER
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.BALANCE,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentBalance,
    positionContent: EOnboardingContentPositions.BOTTOM_CENTER
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.MICROSERVICES,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentMicroservices,
    positionContent: EOnboardingContentPositions.BOTTOM_CENTER
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.TOP_LIVE_SECTION,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentTopLive,
    positionContent: EOnboardingContentPositions.BOTTOM_LEFT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.TOP_LINE_SECTION,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentTopLine,
    positionContent: EOnboardingContentPositions.TOP_LEFT
  },
  {
    attrType: EWelcomeOnboardingDataAttrs.NEAREST_EVENTS,
    onboardingType: EOnboardingType.MAIN_PAGE_WELCOME,
    content: OnboardingContentNearestEvents,
    positionContent: EOnboardingContentPositions.TOP_LEFT
  }
]
