import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { keyframes } from 'styled-components'

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`

export const Square = styled.div`
  aspect-ratio: 109 / 106;
  background: ${(props) => props.theme.colors.custom.primary[7]};
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 70%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`

export const RectangleHorizontal = styled.div`
  grid-column: span 2;
  aspect-ratio: (2 * 109 + 8) / 106;
  background: ${(props) => props.theme.colors.custom.primary[7]};
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 70%;
    height: 100%;
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0)
        );`
        : `background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.4),
          rgba(255, 255, 255, 0)
        );`
    }
    `};
    animation: ${SkeletonShine} 3.5s infinite linear;
  }
`
