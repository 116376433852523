import styled, { css } from 'styled-components'

import Button from 'components/ui/Button/Button'

import { EButtonView, EButtonSize } from 'components/ui/Button/Button.types'

import { IconTooltipTriangle } from 'components/ui/Icon/General/IconTooltipTriangle'

import { EOnboardingContentPositions } from 'components/Onboarding/Onboarding.types'

import { getPositionData } from './utils'

export const StyledOnboardingContent = styled.div<{
  positionCoords?: ReturnType<typeof getPositionData>
}>`
  ${(props) => css`
    ${
      props.positionCoords
        ? css`
            left: ${props.positionCoords.left};
            top: ${props.positionCoords.top};
            visibility: visible;
          `
        : css`
            visibility: hidden;
          `
    }
    position: fixed;
    width: 264px;
    min-height: 136px;
    padding: 16px;
    border-radius: 4px;
    background-color: ${props.theme.colors.fixed.primary[0]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  `}
`

export const StyledOnboardingContentHeader = styled.h3`
  ${(props) => css`
    margin-bottom: 10px;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
    color: ${props.theme.colors.fixed.primary[95]};
  `}
`

export const StyledOnboardingContentText = styled.p`
  ${(props) => css`
    margin-bottom: 10px;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.regular};
    color: ${props.theme.colors.fixed.primary[90]};
    line-height: 16px;
  `}
`

export const StyledOnboardingContentButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledOnboardingContentButtonNext = styled(Button).attrs({
  view: EButtonView.PRIMARY,
  size: EButtonSize.S
})`
  ${(props) => css`
    height: 32px;
    padding: 8px 16px;
    color: ${props.theme.colors.fixed.primary[0]};
    background-color: ${props.theme.colors.fixed.primary[90]};
  `}
`

export const StyledOnboardingContentButtonSkip = styled(Button).attrs({
  view: EButtonView.SECONDARY,
  size: EButtonSize.S
})`
  ${(props) => css`
    background: transparent;
    height: 32px;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    color: ${props.theme.colors.fixed.primary[50]};
    line-height: 16px;
    padding-left: 0;
  `}
`

export const StyledIconTooltipTriangle = styled(IconTooltipTriangle)<{
  leftTr?: string
  topTr?: string
  position?: EOnboardingContentPositions
}>`
  position: absolute;
  top: -9px;

  ${({ position }) =>
    !!position &&
    position === EOnboardingContentPositions.TOP_LEFT &&
    css`
      top: initial;
      transform: rotate(180deg);
      bottom: -9px;
    `}

  ${(props) =>
    !!props.leftTr &&
    css`
      position: fixed;
      left: ${props.leftTr};
      top: ${props.topTr};
    `}
`
