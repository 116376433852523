import { useEffect, useState } from 'react'

import { usePrevious } from './usePrevious'

export const useCompleted = (loading?: boolean): boolean => {
  const [completed, setCompleted] = useState(false)
  const prevLoading = usePrevious(loading)

  useEffect(() => {
    if (prevLoading !== loading) {
      setCompleted(prevLoading === true && loading === false)
    }
  }, [loading, prevLoading])

  return completed
}
