import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { IServiceIconProps } from '../../Icon.types'

export const StyledIconCasino = styled(StyledIcon)<IServiceIconProps>`
  ${({ theme }) => {
    return css`
      .text {
        fill: ${theme.colors.default.primary[90]};
      }
    `
  }}
`
