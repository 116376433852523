import styled from 'styled-components'

import { rowGapPrefixes } from 'utils/styled/stylePrefixes'

export const StyledMainWrapper = styled.div`
  display: grid;
  align-content: baseline;
`

export const StyledEventsSectionWrapper = styled.section`
  display: grid;
  padding: 0 0 1rem;
  ${rowGapPrefixes('14px')}
`
