import styled from 'styled-components'

import { HINT_ATTR_DATA } from 'constants/styles/components/hintAttr/constants'

export const StyledEventCardWrapper = styled.div`
  display: grid;
  height: 141px;
  width: 280px;
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.custom.primary[7]};
  background-color: ${(props) => props.theme.colors.custom.primary[8]};

  &:focus-within {
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }
`

export const StyledEventCardWrapperTop = styled.div`
  width: 280px;
`

export const StyledEventCardMetaWrapper = styled.div`
  display: grid;
`

export const StyledEventCardHeaderWrapper = styled.div`
  padding: 1rem 1rem 0;
  display: grid;
`

export const StyledEventCardHeader = styled.div`
  font-size: ${(p) => p.theme.fonts.size.s};
  font-weight: ${(p) => p.theme.fonts.weight.normal};
  color: ${(p) => p.theme.colors.default.primary[50]};
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  padding-bottom: 4px;

  &${HINT_ATTR_DATA.body} {
    max-width: 100%;
  }
`

export const StyledEventCardName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  line-height: 12px;
  text-overflow: ellipsis;
`

export const StyledEventCardCompetitor = styled.div`
  display: grid;
  column-gap: 0.25rem;
  grid-template-columns: auto auto 1fr;
  align-items: center;

  color: ${(p) => p.theme.colors.default.primary[80]};
  font-weight: ${(p) => p.theme.fonts.weight.semiBold};
  font-size: ${(p) => p.theme.fonts.size.m};
  height: 1.25rem;
`

export const StyledEventCardDate = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.fonts.size.xxs};
  color: ${(p) => p.theme.colors.default.primary[50]};
`

export const StyledAdditionalInfoWrapper = styled.div`
  line-height: 16px;
  padding: 8px 0 8px 16px;
`

export const StyledCurrentServerFlag = styled.div`
  width: 2px;
  height: 100%;
  border-radius: 1px;

  background-color: ${(p) => p.theme.colors.default.green[50]};
`

export const EventCardDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.custom.primary[7]};
`
