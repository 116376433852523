import styled, { css } from 'styled-components'

import { StyledIcon } from '../../Icon.styled'
import { EServiceIconPostion, IServiceIconProps } from '../../Icon.types'

export const StyledSportGames = styled(StyledIcon)<IServiceIconProps>`
  ${({ position, isActive, theme }) => {
    if (position === EServiceIconPostion.HEADER) {
      return css`
        path {
          fill: ${theme.colors.fixed.primary[0]};
        }

        .games {
          fill: ${theme.colors.default.primary[100]};
        }
      `
    }

    if (!isActive) {
      return css`
        path {
          fill: ${theme.colors.custom.primary[16]};
        }
        .games {
          fill: ${theme.colors.default.primary[0]};
        }
      `
    }
  }}
`
