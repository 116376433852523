import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import loadable from '@loadable/component'

import { Event } from 'betweb-openapi-axios'

import isEqual from 'react-fast-compare'

import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useOnboardingAttr } from 'components/Onboarding/hooks'

import { EOnboardingType } from 'components/Onboarding/Onboarding.types'

import { EventsTableSkeleton } from 'components/Line/components/Table/components/EventsTableSkeleton'

import { SportsList, SportsListSkeleton } from './SportsList'
import { MainPageEventsContext } from './MainPageEvents.context'
import {
  EMainPageEventsList,
  MainPageEventsProps
} from './MainPageEvents.types'
import {
  EVENTS_TO_ONBOARDING_ATTRS_MAP,
  getMainPageEventsConfiguration
} from './MainPageEvents.constants'
import { StyledSportsListWrapper } from './SportsList/SportsList.styled'
import {
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper
} from './MainPageEvents.styled'

const VirtualizedEvents = loadable(
  () => import('./EventsTable/VirtualEventsTable'),
  {
    fallback: <EventsTableSkeleton />,
    ssr: false
  }
)

const EventsTable = loadable(() => import('./EventsTable/EventsTable'), {
  fallback: <EventsTableSkeleton />,
  ssr: false
})

const UpcomingEvents: FC<MainPageEventsProps> = memo(
  ({ mainPageEventsListType }) => {
    const isEntityTagsEnabled = useFeatureFlag(
      EFeatureFlags.ENTITY_TAGS_ENABLED
    )

    const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

    const {
      untranslatedTitle,
      selectLoading,
      selectEvents,
      selectIsEventsIdsBySport
    } = config[mainPageEventsListType]

    const { t } = useTranslation()

    const events = useSelector(selectEvents)
    const loading = useSelector(selectLoading)
    const isEventsIdsBySport = useSelector(selectIsEventsIdsBySport)
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {
      if (loading === false) {
        setInitialLoad(false)
      }
    }, [loading])

    const showSkeleton = initialLoad || loading

    const isEmpty =
      initialLoad === false &&
      loading === false &&
      events.length === 0 &&
      isEventsIdsBySport

    if (isEmpty) {
      return null
    }

    return (
      <UpcomingEventsTable
        events={events}
        title={t(untranslatedTitle)}
        showSkeleton={showSkeleton}
        type={mainPageEventsListType}
      />
    )
  }
)

interface IOptimizedUpcomingEvents {
  events: Event[]
  title: string
  showSkeleton: boolean
  type: EMainPageEventsList
}

/*
It depends only on events, title, showSkeleton, type which we can easily to check on isEqual from react-fast-compare
That way it doesn't re-render each time some event, which not presented in list, changed (by SSE i.e.).
*/

const UpcomingEventsTable: FC<IOptimizedUpcomingEvents> = React.memo(
  (props) => {
    const { events, title, showSkeleton, type } = props

    const { onboardingAttr } = useOnboardingAttr(
      EVENTS_TO_ONBOARDING_ATTRS_MAP[type],
      EOnboardingType.MAIN_PAGE_WELCOME
    )

    const mainPageCtx = useMemo(
      () => ({ mainPageEventsListType: type, scrollToIndex: () => {} }),
      [type]
    )

    return (
      <MainPageEventsContext.Provider value={mainPageCtx}>
        <StyledMainPageEventsWrapper>
          <StyledMainPageEventsHeaderWrapper>
            <StyledMainPageEventsHeader {...onboardingAttr}>
              {title}
            </StyledMainPageEventsHeader>
          </StyledMainPageEventsHeaderWrapper>

          {showSkeleton ? (
            <>
              <SportsListSkeleton mainPageEventsListType={type} />
            </>
          ) : (
            <>
              <StyledSportsListWrapper>
                <SportsList />
              </StyledSportsListWrapper>
              <VirtualizedEvents events={events} showSkeleton={showSkeleton} />
            </>
          )}
        </StyledMainPageEventsWrapper>
      </MainPageEventsContext.Provider>
    )
  },
  isEqual
)

export default UpcomingEvents
