import React, { FC } from 'react'

import { IconProps } from '../Icon.types'
import { Icon } from '../Icon'

export const IconClose: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.70711 7.0006L13.3536 1.35416L12.6465 0.647049L7.00001 6.2935L1.35356 0.647049L0.646454 1.35416L6.2929 7.0006L0.646454 12.647L1.35356 13.3542L7.00001 7.70771L12.6465 13.3542L13.3536 12.6471L7.70711 7.0006Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}
