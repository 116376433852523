import { EOnboardingContentPositions } from 'components/Onboarding/Onboarding.types'
import { TOnboardingMaskProps } from 'components/Onboarding/components/OnboardingMask/OnboardingMask.types'

import { ICoordTooltip } from './OnboardingContent.types'

const getPositionLeft = (
  itemNodeRectProps: TOnboardingMaskProps,
  onboardingContentBlockProps: HTMLDivElement
): string => {
  let left = 0
  const windowWidth = window.innerWidth

  if (
    windowWidth <=
    itemNodeRectProps.leftTopX + onboardingContentBlockProps.clientWidth
  ) {
    left =
      itemNodeRectProps.leftTopX - onboardingContentBlockProps.offsetWidth / 2
  }
  if (windowWidth <= left + onboardingContentBlockProps.clientWidth + 10) {
    left = windowWidth - onboardingContentBlockProps.clientWidth - 10
  }
  return `${left}px`
}

export const getPositionData = ({
  position,
  itemNodeRectProps,
  onboardingContentBlockProps
}: {
  position: EOnboardingContentPositions
  itemNodeRectProps: TOnboardingMaskProps
  onboardingContentBlockProps: HTMLDivElement
}): ICoordTooltip => {
  const data = {
    [EOnboardingContentPositions.BOTTOM_CENTER]: {
      left: getPositionLeft(itemNodeRectProps, onboardingContentBlockProps),
      top: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height + 8}px`,
      leftTr: `${
        itemNodeRectProps.leftTopX + itemNodeRectProps.width / 2 - 6
      }px`,
      topTr: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height - 1}px`
    },
    [EOnboardingContentPositions.BOTTOM_RIGHT]: {
      left: `${
        itemNodeRectProps.leftTopX +
        itemNodeRectProps.width -
        onboardingContentBlockProps.offsetWidth
      }px`,
      top: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height + 8}px`
    },
    [EOnboardingContentPositions.BOTTOM_LEFT]: {
      left: `${itemNodeRectProps.leftTopX}px`,
      top: `${itemNodeRectProps.leftTopY + itemNodeRectProps.height + 8}px`
    },
    [EOnboardingContentPositions.TOP_LEFT]: {
      left: `${itemNodeRectProps.leftTopX}px`,
      top: `${
        itemNodeRectProps.leftTopY -
        onboardingContentBlockProps.offsetHeight -
        8
      }px`
    },
    [EOnboardingContentPositions.TOP_RIGHT]: {
      left: `${
        itemNodeRectProps.leftTopX +
        itemNodeRectProps.width -
        onboardingContentBlockProps.offsetWidth
      }px`,
      top: `${
        itemNodeRectProps.leftTopY -
        onboardingContentBlockProps.offsetHeight -
        8
      }px`
    }
  }

  return data[position]
}
