import React, { FC } from 'react'

import { IconProps } from '../Icon.types'
import { Icon } from '../Icon'

export const IconTooltipTriangle: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      width="20"
      height="7"
      viewBox="0 0 20 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81789 0.989961C9.65659 -0.0164806 11.2203 0.0417862 11.9818 1.10785L13.9762 3.90006C15.3658 5.84544 17.6093 7 20 7V7H0H0.0625248C2.43676 7 4.68834 5.94542 6.20829 4.12148L8.81789 0.989961Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
