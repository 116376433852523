import React from 'react'

import { useMainPageFetchData } from 'og-core/providers/main-page/useMainPageFetchData'

import { ETestData } from 'utils/testData'

import { OnboardingNotification } from 'components/Main/Onboarding'

import { Banners } from './Banners'
import { StyledEventsSectionWrapper, StyledMainWrapper } from './Main.styled'
import { Sponsors } from './Sponsors'
import MainPageEventsList from './MainPageEvents/MainPageEventsList'

export const Main = () => {
  useMainPageFetchData()

  return <MainPageContent />
}

const MainPageContent = () => {
  return (
    <StyledMainWrapper data-test-id={ETestData.TestMainPage}>
      <OnboardingNotification />
      <Banners />
      <Sponsors />
      <StyledEventsSectionWrapper>
        <MainPageEventsList />
      </StyledEventsSectionWrapper>
    </StyledMainWrapper>
  )
}
