import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { IconClose } from 'components/ui/Icon/General/IconClose'
import { EColorsNames } from 'components/ui/ThemeProvider/ThemeProvider.types'

import { IconTutorial } from 'components/ui/Icon/General/IconTutorial'

import {
  IStyledNotification,
  IStyledNotificationText,
  IStyledNotificationTLink
} from './Notification.types'

export const StyledNotificationWrapper = styled.div`
  padding: 8px 0;
`

export const StyledNotification = styled.div<IStyledNotification>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  line-height: 16px;

  ${({
    theme,
    backgroundColor: { name, value } = {
      name: EColorsNames.Blue,
      value: 10
    }
  }) =>
    `
      background-color: ${theme.colors.custom[name][value]}};
    `}
`

export const StyledNotificationText = styled.span<IStyledNotificationText>`
  ${({
    theme,
    textColor: { name, value } = { name: EColorsNames.Primary, value: 33 }
  }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom[name][value]}};
  `}
`

export const StyledNotificationLink = styled(Link)`
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.default.primary[1]};
    border-bottom: 1.1px solid ${theme.colors.default.primary[1]};
  `}
`

export const StyledIconTutorial = styled(IconTutorial)``

export const StyledIconClose = styled(IconClose)``

export const StyledNotificationModalWindowText = styled.span<IStyledNotificationTLink>`
  cursor: pointer;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
    text-decoration: none;
  `}
`
