import { ERoutes } from 'routes/routes.types'
import { IconBetGames } from 'components/ui/Icon/Services/BetGames/IconBetGames'
import { IconAviatorGame } from 'components/ui/Icon/Services/Aviator/IconAviator'

import { IconSportGames } from 'components/ui/Icon/Services/SportGames/IconSportGames'
import { IconLiveGames37 } from 'components/ui/Icon/Services/LiveGames/IconLiveGames'
import { IconTwainSport } from 'components/ui/Icon/Services/TwainSport/IconTwainSport'
import { IconCasino } from 'components/ui/Icon/Services/Casino/IconCasino'
import { IconFastGames } from 'components/ui/Icon/Services/FastGames/IconFastGames'
import { IconCyberbet } from 'components/ui/Icon/Services/Cyberbet/IconCyberbet'
import { IconVsport } from 'components/ui/Icon/Services/VSport/IconVsport'

import { ETurboGamesIds } from 'components/TurboGames/types'

import {
  ESponsors,
  ImagesSet,
  SponsorsItemImgProps,
  SponsorsItemProps
} from './Sponsors.types'

export const SPONSORS_LIST: SponsorsItemProps[] = [
  {
    url: ERoutes.Casino,
    icon: IconCasino,
    name: 'Casino'
  },
  {
    url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
    icon: IconAviatorGame,
    name: 'Aviator'
  },
  {
    url: ERoutes.BetGames,
    icon: IconBetGames,
    name: 'Bet Games'
  },
  {
    url: ERoutes.TwainGames,
    icon: IconTwainSport,
    name: 'Twain sport'
  },
  {
    url: ERoutes.LiveGames,
    icon: IconLiveGames37,
    name: 'Live Games 37'
  },
  {
    url: ERoutes.SportGamesLobby,
    icon: IconSportGames,
    name: 'SportGames TV'
  },
  {
    url: ERoutes.TurboGames,
    icon: IconFastGames,
    name: 'Fast games'
  },
  {
    url: ERoutes.CyberbetGames,
    icon: IconCyberbet,
    name: 'Cyberbet'
  },
  {
    url: ERoutes.VsportGames,
    icon: IconVsport,
    name: 'Vsport'
  },
  {
    url: ERoutes.Frigg,
    icon: IconCyberbet,
    name: 'FRIGG'
  }
]

export const SPONSOR_IMAGES: ImagesSet = {
  [ESponsors.AVIATOR]: {
    '320w': require('assets/images/sponsors/aviator-320w.png'),
    '375w': require('assets/images/sponsors/aviator-375w.png')
  },
  [ESponsors.AVIATOR_SMALL]: {
    '320w': require('assets/images/sponsors/aviator-small-320w.png'),
    '375w': require('assets/images/sponsors/aviator-small-375w.png')
  },
  [ESponsors.BET_GAMES]: {
    '320w': require('assets/images/sponsors/betgames-320w.png'),
    '375w': require('assets/images/sponsors/betgames-375w.png')
  },
  [ESponsors.TWAIN_SPORT]: {
    '320w': require('assets/images/sponsors/twain-sport-320w.png'),
    '375w': require('assets/images/sponsors/twain-sport-375w.png')
  },
  [ESponsors.LIVE_GAMES_37]: {
    '320w': require('assets/images/sponsors/live-games-37-320w.png'),
    '375w': require('assets/images/sponsors/live-games-37-375w.png')
  },
  [ESponsors.SPORT_GAMES]: {
    '320w': require('assets/images/sponsors/sport-games-320w.png'),
    '375w': require('assets/images/sponsors/sport-games-375w.png')
  },
  [ESponsors.FAST_GAMES]: {
    '320w': require('assets/images/sponsors/fast-games-320w.png'),
    '375w': require('assets/images/sponsors/fast-games-375w.png')
  },
  [ESponsors.AVIATRIX]: {
    '320w': require('assets/images/sponsors/aviatrix-small-320w.png'),
    '375w': require('assets/images/sponsors/aviatrix-small-375w.png')
  },
  [ESponsors.CYBERBET]: {
    '320w': require('assets/images/sponsors/cyberbet-320w.png'),
    '375w': require('assets/images/sponsors/cyberbet-375w.png')
  },
  [ESponsors.VSPORT]: {
    '320w': require('assets/images/sponsors/vsport-320w.png'),
    '375w': require('assets/images/sponsors/vsport-375w.png')
  }
}

export const SPONSORS_LIST_GRID: SponsorsItemImgProps[] = [
  {
    url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
    srcSet: SPONSOR_IMAGES[ESponsors.AVIATOR],
    name: ESponsors.AVIATOR
  },
  {
    url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
    srcSet: SPONSOR_IMAGES[ESponsors.AVIATOR_SMALL],
    name: ESponsors.AVIATOR_SMALL
  },
  {
    url: ERoutes.TurboGames,
    srcSet: SPONSOR_IMAGES[ESponsors.FAST_GAMES],
    name: ESponsors.FAST_GAMES
  },
  {
    url: ERoutes.BetGames,
    srcSet: SPONSOR_IMAGES[ESponsors.BET_GAMES],
    name: ESponsors.BET_GAMES
  },
  {
    url: ERoutes.TwainGames,
    srcSet: SPONSOR_IMAGES[ESponsors.TWAIN_SPORT],
    name: ESponsors.TWAIN_SPORT
  },
  {
    url: ERoutes.LiveGames,
    srcSet: SPONSOR_IMAGES[ESponsors.LIVE_GAMES_37],
    name: ESponsors.LIVE_GAMES_37
  },
  {
    url: ERoutes.SportGamesLobby,
    srcSet: SPONSOR_IMAGES[ESponsors.SPORT_GAMES],
    name: ESponsors.SPORT_GAMES
  },
  {
    url: ERoutes.SportGamesLobby,
    srcSet: SPONSOR_IMAGES[ESponsors.AVIATRIX],
    name: ESponsors.AVIATRIX
  },
  {
    url: ERoutes.VsportGames,
    srcSet: SPONSOR_IMAGES[ESponsors.VSPORT],
    name: ESponsors.VSPORT
  }
]

export const SPONSORS_LIST_FLEX: SponsorsItemImgProps[] = [
  {
    url: ERoutes.CyberbetGames,
    srcSet: SPONSOR_IMAGES[ESponsors.CYBERBET],
    name: ESponsors.CYBERBET
  }
]
